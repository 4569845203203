import React, { useState , useEffect, useRef } from 'react';
import '../Privacy.css'

import { useTranslation, Trans } from 'react-i18next';

function Cookies(){
    const { t } = useTranslation()

    return(
        <div className="privacy-wrapper">
            <div className="privacy-title"><Trans i18nKey={"privacytitle"}>
                Privacy and Cookies Policy
                </Trans>
            </div>
            <div className='privacy-date'><Trans i18nKey={"privacydate"}>
                Last Updated: 04/01/2024
                </Trans>
            </div>
            <div className="privacy-chapter"><Trans i18nKey={"privacy1"}>1. Introduction</Trans></div>
        <div className="privacy-text"><Trans i18nKey={"privacy2"}>Welcome to NL Immobilien ("we," "our," or "us"). This Privacy and Cookies Policy aims to inform you about how we collect, use, share, and protect your personal information when you visit our website or use our services. By using our website or services, you consent to the practices outlined in this policy.</Trans></div>
        <div className="privacy-chapter-end"></div>
        <div className="privacy-chapter"><Trans i18nKey={"privacy3"}>2. Information We Collect</Trans></div>
        <div className="privacy-subchapter"><Trans i18nKey={"privacy4"}>2.1 Personal Information</Trans></div>
        <div className="privacy-text"><Trans i18nKey={"privacy5"}>We collect various types of personal information to provide and improve our services. This may include your name, email address, contact information, demographic details, and payment information when applicable. We ensure that the collection and processing of personal information comply with relevant data protection laws.</Trans></div>
        <div className="privacy-subchapter-end"></div>
        <div className="privacy-subchapter"><Trans i18nKey={"privacy6"}>2.2 Non-Personal Information</Trans></div>
        <div className="privacy-text"><Trans i18nKey={"privacy7"}>In addition to personal information, we gather non-personal information to enhance the functionality and performance of our website. This may include IP addresses, browser types, operating systems, and log data. This information is crucial for analyzing trends, administering the site, and improving user experience.</Trans></div>
        <div className="privacy-chapter-end"></div>
        <div className="privacy-chapter"><Trans i18nKey={"privacy8"}>3. How We Collect Information</Trans></div>
        <div className="privacy-text"><Trans i18nKey={"privacy9"}>We employ different methods to collect information:</Trans></div>
        
        <div className="privacy-text"><Trans i18nKey={"privacy10"}>Direct Interactions: You provide information directly when filling out forms, signing up for newsletters, or engaging with our services.</Trans></div>
        
        <div className="privacy-text"><Trans i18nKey={"privacy11"}>Automated Technologies: We use cookies, web beacons, and similar technologies to collect information automatically. These technologies help us understand user behavior, customize content, and improve site navigation.</Trans></div>
        <div className="privacy-chapter-end"></div>
        <div className="privacy-chapter"><Trans i18nKey={"privacy12"}>4. Cookies</Trans></div>
        <div className="privacy-subchapter"><Trans i18nKey={"privacy13"}>4.1 What are Cookies?</Trans></div>
        <div className="privacy-text"><Trans i18nKey={"privacy14"}>Cookies are small text files stored on your device when you interact with our website. They serve various functions, such as recognizing you upon return visits, storing user preferences, and tracking how you navigate our site.</Trans></div>
        <div className="privacy-subchapter-end"></div>
        <div className="privacy-subchapter"><Trans i18nKey={"privacy15"}>4.2 Types of Cookies</Trans></div>
       <div className="privacy-text"><Trans i18nKey={"privacy16"}>Essential Cookies: These are necessary for the basic functionality of the website, such as providing secure login and facilitating e-commerce transactions.</Trans></div>
       
        <div className="privacy-text"><Trans i18nKey={"privacy17"}>Analytical/Performance Cookies: These cookies help us analyze how users interact with our website, allowing us to measure and improve performance.</Trans></div>
        
        <div className="privacy-text"><Trans i18nKey={"privacy18"}>Functionality Cookies: These cookies enhance your experience by remembering your preferences and settings.</Trans></div>
        
        <div className="privacy-text"><Trans i18nKey={"privacy19"}>Advertising/Targeting Cookies: These cookies track your online activities and tailor advertisements based on your interests.</Trans></div>
        <div className="privacy-subchapter-end"></div>
        <div className="privacy-subchapter"><Trans i18nKey={"privacy20"}>4.3 Managing Cookies</Trans></div>
        <div className="privacy-text"><Trans i18nKey={"privacy21"}>You can manage cookie preferences through your browser settings. However, please note that disabling certain cookies may impact the functionality and user experience on our website.</Trans></div>
        <div className="privacy-chapter-end"></div>
        <div className="privacy-chapter"><Trans i18nKey={"privacy22"}>5. How We Use Your Information</Trans></div>
        <div className="privacy-text"><Trans i18nKey={"privacy23"}>We use the collected information for various purposes:</Trans></div>
        
        <div className="privacy-text"><Trans i18nKey={"privacy24"}>Providing and Improving Services: We use personal information to deliver and enhance the services you request.</Trans></div>
        
        <div className="privacy-text"><Trans i18nKey={"privacy25"}>Personalization: Non-personal information, such as cookies, helps us tailor content and recommendations to your preferences.</Trans></div>
        
        <div className="privacy-text"><Trans i18nKey={"privacy26"}>Communication: We may use your contact details to communicate with you about services, updates, and promotions.</Trans></div>
        
        <div className="privacy-text"><Trans i18nKey={"privacy27"}>Marketing and Advertising: With your consent, we may use your information for targeted advertising and promotional campaigns.</Trans></div>
        <div className="privacy-chapter-end"></div>
        <div className="privacy-chapter"><Trans i18nKey={"privacy28"}>6. Sharing Your Information</Trans></div>
        <div className="privacy-text"><Trans i18nKey={"privacy29"}>We may share your information with third parties under specific circumstances:</Trans></div>
        
        <div className="privacy-text"><Trans i18nKey={"privacy30"}>Service Providers: We may engage third-party service providers to assist with various aspects of our operations, such as website hosting, analytics, and marketing.</Trans></div>
        
        <div className="privacy-text"><Trans i18nKey={"privacy31"}>Legal Compliance: We may disclose information if required by law or to protect our rights, users, or others.</Trans></div>
        
        <div className="privacy-text"><Trans i18nKey={"privacy32"}>Consent: We share information with your consent or as explicitly outlined at the time of collection.</Trans></div>
        <div className="privacy-chapter-end"></div>
        <div className="privacy-chapter"><Trans i18nKey={"privacy33"}>7. Security</Trans></div>
        <div className="privacy-text"><Trans i18nKey={"privacy34"}>We take the security of your information seriously and implement measures to safeguard it from unauthorized access, disclosure, alteration, or destruction. However, no method of data transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</Trans></div>
        <div className="privacy-chapter-end"></div>
        <div className="privacy-chapter"><Trans i18nKey={"privacy35"}>8. Your Rights</Trans></div>
        <div className="privacy-text"><Trans i18nKey={"privacy36"}>You have rights regarding your personal information, including:</Trans></div>
        
        <div className="privacy-text"><Trans i18nKey={"privacy37"}>Access: You can request access to the personal information we hold about you.</Trans></div>
        
        <div className="privacy-text"><Trans i18nKey={"privacy38"}>Correction: You have the right to correct any inaccuracies in your personal information.</Trans></div>
        
        <div className="privacy-text"><Trans i18nKey={"privacy39"}>Deletion: You can request the deletion of your personal information under certain circumstances.</Trans></div>
        <div className="privacy-chapter-end"></div>
        <div className="privacy-chapter"><Trans i18nKey={"privacy40"}>9. Changes to this Policy</Trans></div>
        <div className="privacy-text"><Trans i18nKey={"privacy41"}>We may update this policy periodically to reflect changes in our practices, legal requirements, or technological advancements. The "Last Updated" date at the top of the policy indicates when the most recent changes were made. We encourage you to review this policy regularly.</Trans></div>
        <div className="privacy-chapter-end"></div>
        <div className="privacy-chapter"><Trans i18nKey={"privacy42"}>10. Contact Us</Trans></div>
        <div className="privacy-text"><Trans i18nKey={"privacy43"}>If you have questions, concerns, or requests regarding this privacy and cookies policy, please contact us at info@nli.com. We are committed to addressing your inquiries promptly and transparently.</Trans></div>
        </div>
    )
}

export default Cookies