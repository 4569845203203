import React, { useState , useEffect, useRef } from 'react';
import axios from 'axios';
import './Article.css'
import Select from 'react-select'

import i18next from 'i18next';
import { useTranslation, Trans } from 'react-i18next';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Blocks from './Blocks'

import { UploadArticleFeaturedImage } from '../Shared/UploadImages/UploadImages'
import { render } from '@testing-library/react';

import { validateUrl, getLanguageWord, formatDate } from '../Shared/Functions/Functions'

import AuthorImg from '../About/Person1.png'
import LinkedIn from './linkedin.svg'

function Article({showRegister, id, logged}){
    const [article, setArticle] = useState({
        id: id,
        url: 'article'+id,
        title: 'Default Title',
		blocks: [],
		featured: '',
		date: new Date(),
		category: 'Property Management',
        translation:{
            en: {
                ['article'+id+'title']: 'English Title',
            },
            de: {
                ['article'+id+'title']: 'Deutsch Title',
            },
            gr: {
                ['article'+id+'title']: 'Greek Title',
            }
        }
    })
    useEffect(()=>{
    	getArticle()
  	}, [window.location.href])

    //GET
	const getArticle = async () => {
		let result = await axios.post('/getarticle', {id: id})
		let response = await addDateTranslation(result.data.response)
		await addTranslations(response)
		await setArticle(response)
		setLoading(false)
	}

    //DELETE
	const deleteArticle = async() => {
		let result = await axios.post('/deletearticle', {id: id})
		setTimeout(()=>{window.location.href='/articles'}, 1000)
	}

    //SAVE
	const saveArticle = async(article) => {
		let result = await axios.post('/updatearticle', {article: article})
	}

    const [loading, setLoading] = useState(true)

    //ADD TRANSLATIONS
	const { t } = useTranslation()
	const addTranslations = async (entry) => {
		for (let language in entry.translation){
			await i18next.addResourceBundle(language, 'translation', entry.translation[language], true, true)
			
		}
	}

	//TRANSLATE DATE
	const addDateTranslation = async (entry) => {
		for (let language in entry.translation) {
			entry.translation[language]['article'+id+'date'] = formatDate(entry.date, language)
		}
		return entry
	}

    //DYNAMIC TRANSLATE FIELD
	const GenerateTranslateField = (classname, field, rich=false) => {
		const[clicked, setClicked] = useState(false)
		const idfield = 'article' + article.id + field
		const[changes, setChanges] = useState(null)
		const wrapperRef = useRef(null)
		const stateRef = useRef(changes)
		const loadChanges = () => {
			let newchanges = {
				default: article[field],
				en: article.translation.en[idfield],
				de: article.translation.de[idfield],
				gr: article.translation.gr[idfield],
			}
			setChanges(newchanges)
			stateRef.current = newchanges

		}
		useEffect(()=>{
			loadChanges()
			document.addEventListener("mousedown", handleClickOutside)
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			  };
		  }, [loading])

		const handleChangeState = (language, e) => {
			let newchanges = changes
			if (rich) newchanges[language] = e
			else newchanges[language] = e.target.value
			setChanges(newchanges)
			stateRef.current = newchanges
		}
		const updateChanges = async() => {
			let newarticle = article
			for (let language in stateRef.current){
				if(language==='default') newarticle[field] = stateRef.current[language]
				else newarticle.translation[language][idfield] = stateRef.current[language]
			}
			setArticle(newarticle)
			saveArticle(newarticle)
			await addTranslations(newarticle)
			setClicked(false)
			
		}

		const handleClickOutside = (event) => {
			if(wrapperRef.current && !wrapperRef.current.contains(event.target)) updateChanges()
		}
		  
		if(loading===false){
			if(logged===false){
				if (rich) return <div className={classname}><Trans i18nKey={idfield} components={{ em: <i />, strong: <strong />, u: <u />, ol: <ol />, li: <li />, h1: <h1 />, h2: <h2 />, h3: <h3 />, a: <a />, ul: <ul /> }}>{article[field]}</Trans></div>
				else return <div className={classname}><Trans i18nKey={idfield}>{article[field]}</Trans></div>
			}
			if(logged===true){
				if (clicked===false) 
				if (rich) return <div className={classname} onClick={()=>setClicked(!clicked)}><Trans i18nKey={idfield} components={{ em: <i />, strong: <strong />, u: <u />, ol: <ol />, li: <li />, h1: <h1 />, h2: <h2 />, h3: <h3 />, a: <a />, ul: <ul /> }}>{article[field]}</Trans></div>
				else return <div className={classname + " unselected-wrapper"} onClick={()=>setClicked(!clicked)}><Trans i18nKey={idfield}>{article[field]}</Trans></div>
				if (clicked===true) {
					let inputarr = []
					//if(rich) inputarr.push(<ReactQuill theme="snow" defaultValue={article[field]} onChange={(e)=>handleChangeState('default', e)}/>)
					//else inputarr.push(<div className={classname}><input className='text-input'  autoFocus defaultValue={article[field]} onChange={(e)=>handleChangeState('default', e)}></input></div>)
					for(let language in article.translation){
						if (rich) inputarr.push(<ReactQuill theme="snow" defaultValue={article.translation[language][idfield]} onChange={(e)=>handleChangeState(language, e)}/>)
						else inputarr.push(<div className={classname}><div className='label'>{getLanguageWord(language)}</div><input className='text-input' defaultValue={article.translation[language][idfield]} onChange={(e)=>handleChangeState(language, e)}></input></div>)
					}
					return(
						<div className='translation-input-box' ref={wrapperRef}>
							{inputarr}
						</div>
					)
				}
			}
		}
	}

    //DYNAMIC FIELD SAVE
	const changeField = (field, value) => {
		let art = article
		art[field] = value
		console.log(art)
		setArticle(art)
		saveArticle(art)
		
	}

	//DYNAMIC SELECT Field
	const selectoptions = [
		{value: 'Property Management', label: t('Property Management')},
		{value: 'Property Buying', label: t('Property Buying')},
		{value: 'Property Selling', label: t('Property Selling')},
		{value: 'News', label: t('News')}
	]
	const GenerateSelectField = (classname, field) => {
		const[clicked, setClicked] = useState(false)
		const handleChange = (field, e) => {
			changeField(field, e.value)
			setClicked(!clicked)
		}

		if (logged===false) 
			return <div className={classname}><Trans i18nKey={article[field]}>{article[field]}</Trans> </div>
		if (logged===true) {
			if (clicked==false) 
				return <div className={classname +" unselected-wrapper"} onClick={()=>setClicked(!clicked)}><Trans i18nKey={article[field]}>{article[field]}</Trans></div>
			if (clicked==true) return <div className={classname}><Select className='select-input'  autoFocus options={selectoptions} defaultValue={article[field]} onChange={(e)=>handleChange(field, e)}></Select></div>
		}
	}
    
    //DYNAMIC URL Field
	const GenerateURLField = (classname, field) => {
		const[clicked, setClicked] = useState(false)
		const handleBlur = (field, e) => {
			let newurl = validateUrl(e.target.value)
			changeField(field, newurl)
			setClicked(!clicked)
			window.location.href='/'+String(newurl)
		}
		if (logged==false) return null
		if (logged==true) {
			if (clicked==false) return <div className={classname +" unselected-wrapper"} onClick={()=>setClicked(!clicked)}><div className='label'>URL</div>{article[field]}</div>
			if (clicked==true) return <div className={classname}><div className='label'>URL</div><input className='text-input' autoFocus defaultValue={article[field]} onBlur={(e)=>handleBlur(field, e)} ></input></div>
		}
	}

	const getNewBlockId = () => {
		let maxid = 0
		for(let entry in article.blocks) if (article.blocks[entry].id>=maxid) maxid = article.blocks[entry].id+1
		console.log(maxid)
		console.log(article.blocks)
		return maxid
	}

	const addNewText = () => {
		let newarticle = article
		let id = getNewBlockId()
		let blocks = article.blocks
		let block = {id: id, type: 'text', value: "New text article created!"}
		let translation = article.translation

		blocks.push(block)
		for (let language in translation) translation[language]['article'+article.id+'block'+id] = "This is a new text block"
		
		setArticle({...article, blocks: blocks, translation: translation})
		newarticle.blocks = blocks
		newarticle.translation = translation
		saveArticle(newarticle) 
	}

	const addNewImage = () => {
		let newarticle = article
		let id = getNewBlockId()
		let blocks = article.blocks
		let block = {id: id, type: 'image', value: "Caption for Image Block", url: ""}
		let translation = article.translation

		blocks.push(block)
		for (let language in translation) translation[language]['article'+article.id+'block'+id] = "Caption for " + language + " Image Block"
		
		setArticle({...article, blocks: blocks, translation: translation})
		newarticle.blocks = blocks
		newarticle.translation = translation
		saveArticle(newarticle) 
	}

	const [key, setKey] = useState(0)
	const forceRender = () => setKey(key+1)
	const imageRefresh = async() => {
        await getArticle()
        forceRender()
    }

	const articleinfo = {
		author: 'Nektarios Thamnopoulos',
		date: 'Feb 17, 2024',
		category: 'Property Management'
	}

	const renderArticleInfoDivider = () => { return (<div className='article-info-divider'></div>)}

	const renderArticleInfo = () => {
		return(
		<div className='article-info-wrapper'>
			<div className='article-info-author'><Trans i18nKey={"author-name-1"}></Trans></div>{renderArticleInfoDivider()}
			<div className='article-info-date'><Trans i18nKey={'article'+article.id+'date'}></Trans></div>{renderArticleInfoDivider()}
			{GenerateSelectField('article-info-category', 'category')}
		</div>
		)
	}

	const RenderArticleAuthor = () => {
		const [style, setStyle] = useState({ "--rotate-value": "-20deg" })
		const changeStyle = () => {
			let newvalue = Math.floor(Math.random() * 20) + -10;
			setStyle({"--rotate-value": newvalue+"deg"})
		}
		return(
			<div className='article-author-wrapper'>
				<img className='article-author-image' style={style} onMouseEnter={()=>changeStyle()} src={AuthorImg}></img>
				<div className='article-author-right'>
					<div className='article-author-name'><Trans i18nKey={"author-name-1"}>Nektarios Thamnopoulos</Trans></div>
					<div className='article-author-title'><Trans i18nKey={"author-title-1"}>Founder and Director · NL Immobilien</Trans></div>
					<div className='article-author-description'><Trans i18nKey={"author-description-1"}>Enter beautiful description here</Trans></div>
					<div className='article-author-links'>
						<a target="_blank" href='https://www.linkedin.com/in/nik-thamnopoulos/'><img className='article-author-link' src={LinkedIn}></img></a>
					</div>
				</div>
			</div>
		)
	}

    return(
        <div className='article-background'>
			{logged?
			<div className='article-admin-wrapper'>
				{GenerateURLField('article-url', 'url')}
				{logged?<div className='article-add-text article-admin-button' onClick={()=>addNewText()}><Trans i18nKey={'articleaddtext'}>ADD NEW TEXT BLOCK</Trans></div>:null}
				{logged?<div className='article-add-image article-admin-button' onClick={()=>addNewImage()}><Trans i18nKey={'articleaddimage'}>ADD NEW IMAGE BLOCK</Trans></div>:null}
				{logged?<div className="article-delete article-admin-button" onClick={()=>deleteArticle()}><Trans i18nKey={'articledelete'}>DELETE ARTICLE</Trans></div>:null}
				{logged?<div><div className='label'><Trans i18nKey={"upload-featured"}>Upload Featured Image</Trans></div><UploadArticleFeaturedImage folder={'article'+article.id} refresh={imageRefresh}/></div>:null}
				{logged?<div><div className='label'><Trans i18nKey={"featured-image"}>Featured Image</Trans></div><img className="article-featured-image" src={`${article.featured}?${key}`}></img></div>:null}
			</div>
			:null
			}
			<div className='article-wrapper'>
				<div className='article-title-wrapper'>
					{GenerateTranslateField('article-title', 'title')}
					{renderArticleInfo()}
				</div>
				{Blocks(article, setArticle, saveArticle, logged, getArticle)}
				{RenderArticleAuthor()}
			</div>
		</div>
    )

}

export default Article