import React, { useState , useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Sold , New } from "../Shared/Banners/Banners"
import './MenuProduct.css'

import { useTranslation, Trans } from 'react-i18next';

export function MenuProduct({product, adminOptions=null}){
    const { t } = useTranslation()
    
    const formatCurrency = (amount) => { return "€" + amount.replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,') }
    
    return(
        <div className='menu-product-wrapper'>
            {product.new?<New/>:null}
            {product.sold?<Sold/>:null}
            <NavLink to={'/'+String(product.url)} className="menu-product-images">
                <img className="menu-product-image-1" src={product.images[0]}></img>
                <img className="menu-product-image-2" src={product.images[1]}></img>
            </NavLink>
            <div className='menu-product-text'>
                <div className="menu-product-text-title"><Trans i18nKey={product.id+'name'}>{product.name}</Trans><br/><Trans i18nKey={product.id+'area'}>{product.area}</Trans></div>
			    <div className="menu-product-text-price">{formatCurrency(product.price)}<br/><Trans i18nKey={product.id+'type'}>{product.type}</Trans></div>
            </div>
            {adminOptions}
        </div>
    )
}
