import React, { useState , useEffect, useRef} from 'react';
import { NavLink } from 'react-router-dom';
import './CookiesForm.css'

import { useTranslation, Trans } from 'react-i18next';

function Cookies(){
    const { t } = useTranslation()

    const [cookies, setCookies] = useState(false)
    useEffect(()=>{
        let accept  = localStorage.getItem('nl-immobilien-cookies')
        if(accept===null) setCookies(true)
    }, [])
    const handleAccept = () =>{
        setCookies(false)
        localStorage.setItem('nl-immobilien-cookies', 'true')
    }
    const handleDecline = () =>{
        setCookies(false)
        localStorage.setItem('nl-immobilien-cookies', 'false')
    }
    
    return(
        <div className='cookie-wrapper' show={String(cookies)}> 
            <div className='cookie-text-wrapper'>
                <div className='cookie-title'><Trans i18nKey={"cookie1"}><strong>Cookie Notice</strong></Trans></div>
                <div className='cookie-description'><Trans i18nKey={"cookie2"}>Indulge in a better browsing experience! Our website uses </Trans><NavLink to='/privacy-and-cookies' className="cookie-navlink">cookies</NavLink><Trans i18nKey={"cookie2.5"}> to enhance functionality and personalize content. By clicking "Accept," you're giving us the green light to treat you to a tailored online treat. Feel free to customize your cookie preferences anytime. Enjoy your stay!</Trans></div>
            </div>
            <div className='cookie-button-wrapper'>
                <div className='cookie-button' onClick={()=>handleAccept()}><Trans i18nKey={"cookie3"}>ACCEPT</Trans></div>
                <div className='cookie-button' onClick={()=>handleDecline()}><Trans i18nKey={"cookie4"}>REQUIRED ONLY</Trans></div>
            </div>
            <div className='cookie-bottom-wrapper'>
                <div className='cookie-link'><Trans i18nKey={"cookie5"}>PRIVACY</Trans></div>
                <div className='cookie-link'><Trans i18nKey={"cookie6"}>TERMS</Trans></div>
            </div>
        </div>
    )
}

export default Cookies