import React, { useState , useEffect, useRef } from 'react';
import './Login.css'
import axios from 'axios';
import loginlogo from  './Logo.png'

function Login({logged}){
    const [state, setState] = useState({username: "", password: "", response: ""})
    
    const handleLogin = async () => {
        let res = await axios.post('/login', state)
        window.location.replace('/')
        setState({...state, response: res.data.error})
    }

    const handleLogout = async () => {
        let res = await axios.get('/logout')
        window.location.replace('/')
    }

    if(logged==false){
        return(
            <div className="login-wrapper">
                <img className="login-logo" src={loginlogo}></img>
                <div className="login-username"><input className="login-input" onChange={(e)=>setState({...state, username: e.target.value})}></input></div>
                <div className="login-password"><input className="login-input" type="password" onChange={(e)=>setState({...state, password: e.target.value})}></input></div>
                <div className="login-forgot">Passwort vergessen?</div>
                <div className='login-button' onClick={()=>handleLogin()}>Anmelden</div>
                <div className="login-response">{state.response}</div>
            </div>
        )
    }
    else{
        return(
            <div className="login-wrapper">
                <div className="login-response">Du bist bereits angemeldet!</div>
                <div className="login-logout" onClick={()=>handleLogout()}>Log out</div>
            </div>
        )
    }
}

export default Login