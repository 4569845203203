import React, { useState , useEffect, useRef } from 'react';
import './Sell.css'
import Select from 'react-select'
import ReactSlider from 'react-slider'
import axios from 'axios'

import { useTranslation, Trans } from 'react-i18next';
import { AutocompleteTest } from '../Shared/GoogleMaps/GoogleMaps';

import Apartment from './apartment.svg'
import House from './house.svg'
import Meter from './meter.svg'
import Rooms from './rooms.svg'
import Date from './date.svg'
import Rating1 from './rating1.svg'
import Rating2 from './rating2.svg'
import Rating3 from './rating3.svg'
import Rating4 from './rating4.svg'
import Usage1 from './usage1.svg'
import Usage2 from './usage2.svg'

const SellForm = () => {
    const [formOptions, setFormOptions] = useState({
        housetype: null,
        sqm: 110,
        rooms: 2,
        address: null,
        builtyear: 1970,
        renovationscore: null,
        currentusage: null,
        email: null
    })

    const stepAlign = {
        1: "housetype",
        2: "sqm",
        3: "rooms",
        4: "builtyear",
        5: "renovationscore",
        6: "currentusage",
    }

    

    const [step, setStep] = useState(1)
    const maxstep = 7
    const handleFormButtonClick = (option, value) => {
        setFormOptions({...formOptions, [option]: value})
        setTimeout(()=>stepForward(), 100)
    }
    const checkFormButtonActive = (option, value) => {
        return formOptions[option]===value
    }


    const stepForward = () => {
        setStep(step+1)
    }
    const stepBackward = () => setStep(step-1)


    const backButton = () => {
        return(
            <div className='sell-back' step={step} onClick={()=>stepBackward()} send={String(send)}><Trans i18nKey={"back"}><div className="arrow2">⮜</div></Trans></div>
        )
    }
    
    const serviceChecks = () => {
        return(
            <div className="sell-onlinequote-service-checks">
                <div className="sell-onlinequote-check"><Trans i18nKey={"sellonlinequotecheck1"}>✓ Immediate Estimates:<br></br>Swift, Accurate Results</Trans></div>
                <div className="sell-onlinequote-check  mobilenone"><Trans i18nKey={"sellonlinequotecheck2"}>✓ Personalized Evaluation:<br></br>Precision for Your Property</Trans></div>
                <div className="sell-onlinequote-check mobilenone mobilenone1"><Trans i18nKey={"sellonlinequotecheck3"}>✓ Free Property Assessment:<br></br>Clear, Costless Insights</Trans></div>
            </div>
        )
    }

    const forwardButton = () => {
        
        const handleForwardButtonClick = () => {
            console.log(step)
            if(step!==maxstep) {
                if(formOptions[stepAlign[step]]!==null) stepForward()
            }
            else handleSubmit()
        }
        return(
            <div className='sell-forward' step={step} onClick={()=>handleForwardButtonClick()} send={String(send)}>
                {step===maxstep
                ?<Trans i18nKey={"submit"}>SUBMIT</Trans>
                :<Trans i18nKey={"next"}>NEXT<div className="arrow">⮞</div></Trans>}
            </div>
        )
    }

    const progressBar = () => {
        return(
            <div className="sell-bar-wrapper">
                <div className="sell-empty-bar">
                      <div className='sell-full-bar' style={{width: parseInt(((step-1)/(maxstep-1))*100)+'%'}}></div>
                          
                    </div>
            </div>
        )
    }
    //FINAL SELL FORM 
    const original = {
		'First name': {required: true, value: '', check: 'empty'},
		'Last name': {required: true, value: '', check: 'empty'},
		'Email address': {required: true, value: '', check: 'empty'},
		'Phone number': {required: true, value: '', check: 'empty'},
		'Address': {required: true, value: '', check: 'empty'},
		'Postcode': {required: true, value: '', check: 'empty'}
	}
	const [data, setData] = useState(original)
	const [submit, setSubmit] = useState(false)
	const [key , setKey] = useState(0)
	const forceRender = () => setKey(key+1)

	const SellFormElement = (field) => {
		const [rq, setRq] = useState(false)
		const handleRequired = () => {
			if(submit==true && data[field].required==true && data[field].value.length==0) setRq(true)
			else setRq(false)
		}
		useEffect(()=>{
			handleRequired()
		  }, [data, submit])
		return(
		<div className="sell-middle-form-element">
			<div className="sell-middle-form-field" rq={String(rq)}><Trans i18nKey={field}>{field}</Trans> {data[field].required?'*':null}</div>
			<div className="sell-middle-form-box"><input className="sell-middle-form-input user-input" onChange={(e)=>setData({...data, [field]: {...data[field], value: e.target.value}})}></input></div>
		</div>
		)
	}

	const [send, setSend] = useState(false)

	const handleConfirmSubmit = () => {
		let confirmSubmit = true
		console.log(data)
		for(let entry in data){
			if(data[entry].required==true && data[entry].check=='empty' && data[entry].value.length==0) confirmSubmit=false
		}
		return confirmSubmit
	}

	const handleSubmit = () => {
		setSubmit(true)
		forceRender()
		let confirmSubmit=handleConfirmSubmit()
		if(confirmSubmit==true){
			console.log("SENT")
			axios.post('/sellform', {data: formOptions, userData: data, language: localStorage.getItem('language').toLowerCase()})
			setSend(true)
		}
	}

    const renderFinalForm = () => {
        return(
            <div>
                <div className='sell-step-wrapper opaqueStep' step={step} send={String(send)}>
                    <div>
                        <div className="sell-onlinequote-header"><Trans i18nKey={"sellonlinequoteheader7"}>Contact Details and Address</Trans></div>
                        <div className="sell-onlinequote-form1"><Trans i18nKey={"sellonlinequoteform7"}>What is your property's address and your contact details?</Trans></div>
                                                
                        <div className="sell-onlinequoteform-wrapper">
                            {SellFormElement('First name')}
                            {SellFormElement('Last name')}
                            {SellFormElement('Address')}
                            {SellFormElement('Postcode')}
                            {SellFormElement('Email address')}
                            {SellFormElement('Phone number')}                      
                        </div>
                    </div>
                </div>
                <div className='sell-step-wrapper opaqueStep2' send={String(send)}>
                    <div className="sell-onlinequote-header"><Trans i18nKey={"sellonlinequoteheader8"}>Thank you!</Trans></div>
                    <div className="sell-onlinequote-form1"><Trans i18nKey={"sellonlinequoteform8"}>Thank you for submitting the form! The estimation will be emailed to you shortly.</Trans></div>
                </div>      
            </div>		
        )
    }


    // STEPS
    const steps = () => {
        
        return(
            <div className='sell-step-wrapper'>
                {renderFinalForm()}
            {step===1?
            <div className='sell-step-wrapper'>

                <div className="sell-onlinequote-header"><Trans i18nKey={"sellonlinequoteheader"}>Get an Estimate: Free Online Property Value Calculator</Trans></div>
                        <div className="sell-onlinequote-form1"><Trans i18nKey={"sellonlinequoteform1"}>What type of property do you want to determine the market price for?</Trans></div>
                        
                        <div className="sell-onlinequote-wrapper">
                            
                            <div className="sell-onlinequote-button"  active={String(checkFormButtonActive("housetype", "apartment"))} onClick={()=>handleFormButtonClick("housetype", "apartment")}>
                                <div className="sell-curtain" active={String(checkFormButtonActive("housetype", "apartment"))}></div>
                                <div className="sell-online" >
                                    <img src={Apartment} className="sell-online-image"></img>
                                    <div className="sell-button-text"><Trans i18nKey={"sellbuttonapartment"}>APARTMENT</Trans></div>
                                </div>
                            </div>
                            
                            <div className="sell-onlinequote-button" active={String(checkFormButtonActive("housetype", "house"))} onClick={()=>handleFormButtonClick("housetype", "house")}>
                                <div className="sell-curtain" active={String(checkFormButtonActive("housetype", "house"))}></div>
                                <div className="sell-online" >
                                    <img src={House} className="sell-online-image"></img>
                                    <div className="sell-button-text"><Trans i18nKey={"sellbuttonhouse"}>HOUSE</Trans></div>
                                </div>
                            </div>
                    
                        </div>
            
            </div>
            :step===2?
            <div className='sell-step-wrapper'>
                <div className="sell-onlinequote-header"><Trans i18nKey={"sellonlinequoteheader2"}>Living Space: Determining Residence Dimensions</Trans></div>
                    <div className='sell-step2-wrapper'>

                    <div className="sell-onlinequote-form1"><Trans i18nKey={"sellonlinequoteform2"}>What is the measurement of your total living space?</Trans></div>
                    
                                <div className='sell-step2'>
                                
                                            <ReactSlider
                                                className="sell-slider"
                                                value={formOptions.sqm}
                                                min={20}
                                                max={200}
                                                step={1}
                                                defaultValue={formOptions.sqm}
                                                thumbClassName="sell-slider-thumb"
                                                trackClassName="sell-slider-track"
                                                renderThumb={(props)=><div {...props} className="sell-slider-thumb"><div className='sell-slider-value'>{formOptions.sqm}</div></div>}
                                                onChange={(e)=> setFormOptions({...formOptions, sqm: e})}
                                            />

                                            <div className='sell-slider-minmax'>
                                                <div className='sell-slider-min'><Trans i18nKey={"sellminvalue"}> ≤ 20 m²</Trans></div>
                                                <div className='sell-slider-max'><Trans i18nKey={"sellmaxvalue"}> ≥ 200 m²</Trans></div>
                                            </div>

                                <div className='sell-slider-big-values'>
                                                <div className='sell-slider-big-value'>{formOptions.sqm}</div>
                                                <div className='sell-slider-big-text'><Trans i18nKey={"sellbigvalue"}> m²</Trans></div>
                                        </div>
                                        <img src={Meter} className="sell-online-image2"></img>
                                        </div>
                                                
                                    </div>
                        </div>
           :step===3?
            <div className='sell-step-wrapper'>
                <div className="sell-onlinequote-header"><Trans i18nKey={"sellonlinequoteheader3"}>Room Count: Your Residence</Trans></div>
                    <div className='sell-step2-wrapper'>

                    <div className="sell-onlinequote-form1"><Trans i18nKey={"sellonlinequoteform3"}>How many rooms does your living space have?</Trans></div>
                    
                                <div className='sell-step2'>
                                
                                            <ReactSlider
                                                className="sell-slider"
                                                value={formOptions.rooms}
                                                min={1}
                                                max={10}
                                                step={1}
                                                defaultValue={formOptions.rooms}
                                                thumbClassName="sell-slider-thumb"
                                                trackClassName="sell-slider-track"
                                                renderThumb={(props)=><div {...props} className="sell-slider-thumb"><div className='sell-slider-value'>{formOptions.rooms}</div></div>}
                                                onChange={(e)=> setFormOptions({...formOptions, rooms: e})}
                                            />

                                            <div className='sell-slider-minmax'>
                                                <div className='sell-slider-min'>≤ 1</div>
                                                <div className='sell-slider-max'>≥ 10</div>
                                            </div>

                                <div className='sell-slider-big-values'>
                                                <div className='sell-slider-big-value'>{formOptions.rooms}</div>
                                                <div className='sell-slider-big-text'>{formOptions.rooms>1?<Trans i18nKey={"sellroomvalue"}>Rooms</Trans>:<Trans i18nKey={"sellroomvalue2"}>Room</Trans>}</div>
                                        </div>
                                        <img src={Rooms} className="sell-online-image2"></img>
                                        </div>
                                                
                                    </div>
                        </div>
                        :step===4?
                        <div className='sell-step-wrapper'>
                            <div className="sell-onlinequote-header"><Trans i18nKey={"sellonlinequoteheader4"}>Property Construction Year Details</Trans></div>
                                <div className='sell-step2-wrapper'>

                                <div className="sell-onlinequote-form1"><Trans i18nKey={"sellonlinequoteform4"}>What is the construction year of your property?</Trans></div>
                                
                                            <div className='sell-step2'>
                                            
                                                        <ReactSlider
                                                            className="sell-slider"
                                                            value={formOptions.builtyear}
                                                            min={1900}
                                                            max={2023}
                                                            step={1}
                                                            defaultValue={formOptions.builtyear}
                                                            thumbClassName="sell-slider-thumb"
                                                            trackClassName="sell-slider-track"
                                                            renderThumb={(props)=><div {...props} className="sell-slider-thumb"><div className='sell-slider-value'>{formOptions.builtyear}</div></div>}
                                                            onChange={(e)=> setFormOptions({...formOptions, builtyear: e})}
                                                        />

                                                        <div className='sell-slider-minmax'>
                                                            <div className='sell-slider-min'>≤ 1900</div>
                                                            <div className='sell-slider-max'>≥ 2023</div>
                                                        </div>

                                            <div className='sell-slider-big-values'>
                                                            <div className='sell-slider-big-value'>{formOptions.builtyear}</div>
                                                            <div className='sell-slider-big-text'><Trans i18nKey={"sellroomyear"}>Construction Year</Trans></div>
                                                    </div>
                                                    <img src={Date} className="sell-online-image2"></img>
                                                    </div>
                                                            
                                                </div>
                                    </div>
                        :step===5?
        <div className='sell-step-wrapper'>
        
            <div className="sell-onlinequote-header"><Trans i18nKey={"sellonlinequoteheader5"}>Property Condition Grading: Evaluating Your Home's State</Trans></div>
                    <div className="sell-onlinequote-form1"><Trans i18nKey={"sellonlinequoteform5"}>How would you rate the current condition of your property?</Trans></div>
                                    
                                    <div className="sell-onlinequote-wrapper">
                                        
                                        <div className="sell-onlinequote-button"  active={String(checkFormButtonActive("renovationscore", "rating1"))} onClick={()=>handleFormButtonClick("renovationscore", "rating1")}>
                                            <div className="sell-curtain" active={String(checkFormButtonActive("renovationscore", "rating1"))}></div>
                                            <div className="sell-online" >
                                                <img src={Rating1} className="sell-online-image"></img>
                                                <div className="sell-button-text"><Trans i18nKey={"sellbuttonrating1"}>RENOVATION<br></br>NEEDED</Trans></div>
                                            </div>
                                        </div>
                                        
                                        <div className="sell-onlinequote-button" active={String(checkFormButtonActive("renovationscore", "rating2"))} onClick={()=>handleFormButtonClick("renovationscore", "rating2")}>
                                            <div className="sell-curtain" active={String(checkFormButtonActive("renovationscore", "rating2"))}></div>
                                            <div className="sell-online" >
                                                <img src={Rating2} className="sell-online-image"></img>
                                                <div className="sell-button-text"><Trans i18nKey={"sellbuttonrating2"}>FAIR<br></br>CONDITION</Trans></div>
                                            </div>
                                        </div>
                                        <div className="sell-onlinequote-button" active={String(checkFormButtonActive("renovationscore", "rating3"))} onClick={()=>handleFormButtonClick("renovationscore", "rating3")}>
                                            <div className="sell-curtain" active={String(checkFormButtonActive("renovationscore", "rating3"))}></div>
                                            <div className="sell-online" >
                                                <img src={Rating3} className="sell-online-image"></img>
                                                <div className="sell-button-text"><Trans i18nKey={"sellbuttonrating3"}>EXCELLENT<br></br>CONDITION</Trans></div>
                                            </div>
                                        </div>
                                    
                                    <div className="sell-onlinequote-button" active={String(checkFormButtonActive("renovationscore", "rating4"))} onClick={()=>handleFormButtonClick("renovationscore", "rating4")}>
                                            <div className="sell-curtain" active={String(checkFormButtonActive("renovationscore", "rating4"))}></div>
                                            <div className="sell-online" >
                                                <img src={Rating4} className="sell-online-image"></img>
                                                <div className="sell-button-text"><Trans i18nKey={"sellbuttonrating4"}>UNKNOWN<br></br>CONDITION</Trans></div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                        :step===6?
                        <div className='sell-step-wrapper'>
                        
                            <div className="sell-onlinequote-header"><Trans i18nKey={"sellonlinequoteheader6"}>Property Usage Inquiry: Present Functionality</Trans></div>
                                    <div className="sell-onlinequote-form1"><Trans i18nKey={"sellonlinequoteform6"}>How is your property currently being utilized?</Trans></div>
                                                    
                                                    <div className="sell-onlinequote-wrapper" key="1">
                                                        
                                                        <div className="sell-onlinequote-button"  active={String(checkFormButtonActive("currentusage", "usage1"))} onClick={()=>handleFormButtonClick("currentusage", "usage1")}>
                                                            <div className="sell-curtain" active={String(checkFormButtonActive("currentusage", "usage1"))}></div>
                                                            <div className="sell-online" >
                                                                <img src={Usage1} className="sell-online-image"></img>
                                                                <div className="sell-button-text"><Trans i18nKey={"sellbuttonusage1"}>RENTED</Trans></div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="sell-onlinequote-button" active={String(checkFormButtonActive("currentusage", "usage2"))} onClick={()=>handleFormButtonClick("currentusage", "usage2")}>
                                                            <div className="sell-curtain" active={String(checkFormButtonActive("currentusage", "usage2"))}></div>
                                                            <div className="sell-online" >
                                                                <img src={Usage2} className="sell-online-image"></img>
                                                                <div className="sell-button-text"><Trans i18nKey={"sellbuttonusage2"}>SELF<br></br>INHABITED</Trans></div>
                                                            </div>
                                                            </div>
                                                    <div className="sell-onlinequote-button" active={String(checkFormButtonActive("currentusage", "usage3"))} onClick={()=>handleFormButtonClick("currentusage", "usage3")}>
                                                            <div className="sell-curtain" active={String(checkFormButtonActive("currentusage", "usage3"))}></div>
                                                            <div className="sell-online" >
                                                                <img src={Rating4} className="sell-online-image"></img>
                                                                <div className="sell-button-text"><Trans i18nKey={"sellbuttonusage3"}>VACANT</Trans></div>
                                                            </div>
                                                    </div>
                                </div>
                        </div>
                        
                        					

                        :null}
                </div>
                
            
        )
        
    }

    
    console.log("RERENDER")
    return(
        <div className="sell-onlinequote"> 
            {steps()}
            <div className='sell-button-wrapper'>	
                {forwardButton()}
              {progressBar()}
            <div className='sell-bottom-row'>
                {backButton()}
                {serviceChecks()}
            </div>
            </div>
            
        </div>
    )
}

export default SellForm;