import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import AboutTranslation from '../About/Translation.json'
import SellTranslation from '../Sell/Translation.json'
import SaleEntryTranslation from '../SaleEntry/Translation.json'
import BuyTranslation from '../Buy/Translation.json'
import HomeTranslation from '../Home/Translation.json'
import HeaderTranslation from '../Shared/Header/Translation.json'
import FooterTranslation from '../Shared/Footer/Translation.json'
import TermsTranslation from '../Privacy/Terms/Translation.json'
import CookiesTranslation from '../Privacy/Cookies/Translation.json'
import PopupTranslation from '../Shared/Popups/Translation.json'
import CookiesFormTranslation from  '../Shared/CookiesForm/Translation.json'
import ProductsTranslation from '../Products/Translation.json'
import BannerTranslation from '../Shared/Banners/Translation.json'
import HeaderSideTranslation from '../Shared/Header/Translation.json'
import FinancialTranslation from '../Financial/Translation.json'
import ArticleTranslation from '../Articles/Translation.json'
import LanguageTranslation from './Translation.json'


const mergeKeepDistinct = (objectarr) =>{
    let merged = {}
    for(let entry in objectarr){
        for (let locale in objectarr[entry]){
            if((locale in merged)==false) merged[locale] = {translation: {}}
            for(let key in objectarr[entry][locale].translation){
                if((objectarr[entry][locale].translation[key] in merged[locale].translation)==false) merged[locale].translation[key] = objectarr[entry][locale].translation[key]
            }
        }
    }
    return merged
}


const resources = mergeKeepDistinct([
    AboutTranslation,
    SellTranslation,
    BuyTranslation,
    HomeTranslation,
    HeaderTranslation,
    FooterTranslation,
    TermsTranslation,
    CookiesTranslation,
    SaleEntryTranslation,
    PopupTranslation, 
    CookiesFormTranslation,
    PopupTranslation,
    ProductsTranslation,
    BannerTranslation,
    HeaderSideTranslation,
    FinancialTranslation,
    ArticleTranslation,
    LanguageTranslation

])

let language = localStorage.getItem('language')
if(language===null) {
    language='en'
    localStorage.setItem('language', 'en')
}
else language = language.toLowerCase()
i18next
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        lng: language,
        resources: resources,
        interpolation: {
            escapeValue: false
        }
    })