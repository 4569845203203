import React, { useState , useEffect, useLayoutEffect } from 'react';
import { Trans } from 'react-i18next';

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState([0,0])
    const updateWindowSize = () => {
        setWindowSize([window.innerWidth, window.innerHeight])
    }
    useLayoutEffect(() => {
        window.addEventListener('resize', updateWindowSize);
        updateWindowSize();
        return () => window.removeEventListener('resize', updateWindowSize);
    },[])
    return [windowSize[0], windowSize[1]]
}

export const useWindowPosition = () => {
  const [scrollPosition, setPosition] = useState(0);
  useLayoutEffect(() => {
    function updatePosition() {
      var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
      setPosition(scrollTop);
    }
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);
  return scrollPosition;
}

export const reOrder = (arr, index, relativepos) => {
  let element = arr[index]
  let actualpos = index + relativepos
  if(actualpos<0) actualpos = arr.length+actualpos
  if(actualpos>arr.length-1) actualpos = actualpos - arr.length
  arr.splice(index, 1)
  arr.splice(actualpos, 0, element)
  console.log(arr)
  return arr
}

export const priceToFloat = (price) => {
  let num = price.replace(/[^0-9.]/g, "")
  num = parseFloat(num)
  return num
}

export const getParents = (elem) => {

  // Set up a parent array
  var parents = [];

  // Push each parent element to the array
  for ( ; elem && elem !== document; elem = elem.parentNode ) {
      parents.push(elem);
  }

  // Return our parent array
  return parents;

}

export const validateUrl = (url) => {
  console.log(url)
  let newurl = url.replace(/[^0-9A-Za-z-]/g, "")
  console.log(newurl)
  return newurl

}

export const getLanguageWord = (language) => {
  if(language==='en') return ( <Trans i18nKey={"english"}>English</Trans>)
  if(language==='de') return ( <Trans i18nKey={"german"}>German</Trans>)
  if(language==='gr') return ( <Trans i18nKey={"greek"}>Greek</Trans>)
}

export const getLanguageLocale = (language) => {
  if(language==='gr') return 'el'
  else return language
}

export const formatDate = (dt, language) => {
  let date = new Date(dt)
  let locale = getLanguageLocale(language.toLowerCase())
  let formatted = date.toLocaleString(locale, {month: 'short'}) + ' ' + date.getDate() + ', ' + date.getFullYear()
  return formatted
}