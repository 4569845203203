import React, { useState , useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Featured.css'
import { New, Sold } from '../Shared/Banners/Banners'
import {MenuProduct} from './MenuProduct'
import './MenuProduct.css'
import { useWindowSize } from "../Shared/Functions/Functions"
import Select from 'react-select'

import {reOrder} from '../Shared/Functions/Functions'

import axios from 'axios'

import Carousel1_1 from '../SaleEntry/Carousel1_1.png'
import Carousel1_2 from '../SaleEntry/Carousel1_2.png'

import i18next from 'i18next';
import { useTranslation, Trans } from 'react-i18next';

function Featured({category, title='', showButton=true, logged}){
    const [products, setProducts] = useState({})
    const [featured, setFeatured] = useState({category: category, products: []})
    const [key, setKey] = useState(0)
    useEffect(()=>{
    	getProducts()
  	}, [])
    //GET
    const getProducts = async() => {
        let result = await axios.post('/getfeaturedbycategory', {category: category})
        setProducts(result.data.response.products)
        setFeatured(result.data.response.featured)
        await addTranslations(result.data.response.products)
    }
    //SAVE
    const saveFeatured = async() => {
        let result = await axios.post('/addfeaturedbycategory', {category: category, featured: featured})
    }
    //UPDATE
    const updateRender = () => setKey(key+1)
    //TRANSLATION
    //ADD TRANSLATIONS
	const { t } = useTranslation()
	const addTranslations = async (products) => {
        for (let entry in products){
            let product = products[entry]
            for (let language in product.translation){
                await i18next.addResourceBundle(language, 'translation', product.translation[language], true, true)
                
            }
        }
	}
    /*~~~ MOBILE MEDIA QUERY IN JAVASCRIPT ~~~*/
	const [width, height] = useWindowSize()
	var mobile = window.matchMedia("(max-width: 1024px)")
    
    /*~~~ RENDER INDIVIDUAL PRODUCT ~~~*/
    const renderProduct = (id) => {
        const findProduct = () => {
            for (let entry in products){
                if (id == products[entry].id) {return products[entry]}
            }
        }
        //DELETE
        const deleteProduct = async () => {
            let index = featured.products.indexOf(id)
            let newfeatured = featured

            newfeatured.products.splice(index, 1)
            await saveFeatured(newfeatured)
            setFeatured(newfeatured)
            
            updateRender()
        }
        //REORDER
        const reOrderProduct = async (direction) => {
            let newfeatured = featured
            if(direction=='left') newfeatured.products = reOrder(newfeatured.products, newfeatured.products.indexOf(id), -1)
            if(direction=='right') newfeatured.products = reOrder(newfeatured.products, newfeatured.products.indexOf(id), 1)
            await saveFeatured(newfeatured)
            setFeatured(newfeatured)
            
            updateRender()
        }
        let product = findProduct()
        
        const adminOptions =
            <div className='menu-products-admin-wrapper'>
                {logged?<div className='home-featured-remove' onClick={()=>deleteProduct()}><Trans i18nKey={"Remove from"}>Remove from</Trans> {category}</div>:null}
                {logged?
                <div className='home-featured-directions-wrapper'>    
                    <div className="home-featured-left" onClick={()=>reOrderProduct('left')}><Trans i18nKey={"Move Left"}>⇦LEFT</Trans></div>
                    <div className="home-featured-right" onClick={()=>reOrderProduct('right')}><Trans i18nKey={"Move Right"}>RIGHT⇨</Trans></div>
                </div>
                :null}

            </div>

        return(
           <MenuProduct product={product} adminOptions={adminOptions}/>    
        )
    }

    /*~~~ RENDER SELECT FROM PRODUCTS TO INSERT TO FEATURED ~~~*/
    const renderSelect = () => {
        //GET OPTIONS BASED ON PRODUCTS
        let options = []
        const getOptions = () => {
            for (let product in products){
                options.push({
                    value: products[product].id,
                    label: products[product].name,
                })
            }
        }
        getOptions()
        const handleChange = async(e) => {
            let newfeatured = featured
            newfeatured['products'].push(e.value)
            await saveFeatured(newfeatured)
            setFeatured(newfeatured)
            updateRender()
        }
        if (featured['products'].length<3) return <Select className="featured-select" options={options} placeholder={t('search')} onChange={(e)=>handleChange(e)}/>
        else return null
    }
    
    /*~~~ RENDER MOBILE ALL PRODUCTS ~~~*/
    if (mobile.matches) return(
        <div className="saleentry-featured-wrapper" key={key}>
			{logged?
            <div className='featured-select-wrapper'>
                {featured['products'].length<3?<div className='label'><Trans i18nKey={"ADD"}>ADD PRODUCT</Trans></div>:null}
                {renderSelect()}
            </div>
            :null}
            <div className="home-featured-top">
				<div className="home-featured-title"><Trans i18nKey={title}>{title}</Trans></div>
			</div>
			<div className="featured-products-wrapper">
                    {featured['products'].length>0?renderProduct(featured.products[0]):null}
                    {featured['products'].length>1?renderProduct(featured.products[1]):null}
                    {featured['products'].length>2?renderProduct(featured.products[2]):null}
			</div>
			{showButton?
            <div className="home-featured-bottom">
				<NavLink to='/buy' className="home-featured-button"><Trans i18nKey={"View all sales"}>VIEW ALL SALES</Trans></NavLink>
			</div>
            :null
            }
        </div>
    )

    /*~~~ RENDER DESKTOP ALL PRODUCTS ~~~*/
    else return(
        <div className="saleentry-featured-wrapper" key={key}>
			{logged?
            <div className='featured-select-wrapper'>
                {featured['products'].length<3?<div className='label'><Trans i18nKey={"ADD"}>ADD PRODUCT</Trans></div>:null}
                {renderSelect()}
            </div>
            :null}
            <div className="home-featured-top">
				<div className="menu-products-title"><Trans i18nKey={title}>{title}</Trans></div>
				{showButton?<NavLink to='/buy' className="home-featured-button"><Trans i18nKey={"View all sales"}>VIEW ALL SALES</Trans></NavLink>:null}
			</div>
            <div className='menu-products-wrapper'>
                {featured['products'].length>0?renderProduct(featured.products[0]):null}
                {featured['products'].length>1?renderProduct(featured.products[1]):null}
                {featured['products'].length>2?renderProduct(featured.products[2]):null}
            </div>
		</div>
    )
}

export default Featured