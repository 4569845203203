import React, { useState , useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import './HeaderSide.css';
import { useWindowPosition } from "../Functions/Functions"
import LanguageSwitch from "../../Translation/LanguageSwitch"

import { useTranslation, Trans } from 'react-i18next';

import Language from './Language.svg'
import Facebook from './Facebook.svg'
import Instagram from './Instagram.svg'

function HeaderSide({isMenuOpen, handleMenuOpen, showRegister}){	
	var yscroll = useWindowPosition()
	const [key, setKey] = useState(0)
	const forceRender = () => {
		setKey(key+1)
		console.log("RERENDER")
	}
	const handleSubscribeClick = () => {
		handleMenuOpen()
		showRegister()
	}
	return(
		<div className="headerside-wrapper" onClick={()=>forceRender()} style={{top: yscroll+'px'}}>
			<div className="headerside-top">
				<div className="headerside-top-element" onClick={()=>handleMenuOpen()}><NavLink to='/buy'><Trans i18nKey={"header1"}>BUY</Trans></NavLink></div>
				<div className="headerside-top-element" onClick={()=>handleMenuOpen()}><NavLink to="/sell"><Trans i18nKey={"header2"}>SELL</Trans></NavLink></div>
				<div className="headerside-top-element" onClick={()=>handleMenuOpen()}><NavLink to="/financial"><Trans i18nKey={"header6"}>FINANCIAL ADVICE</Trans></NavLink></div>
				<div className="headerside-top-element" onClick={()=>handleMenuOpen()}><NavLink to ="/articles"><Trans i18nKey={"header3"}>JOURNAL</Trans></NavLink></div>
			</div>
			<div className="headerside-middle">
				<LanguageSwitch  device="Mobile"/>
				<div className="headerside-middle-element" onClick={()=>handleMenuOpen()}> <NavLink to="/about"><Trans i18nKey={"header4"}> ABOUT </Trans></NavLink></div>
				<div className="headerside-middle-element" onClick={()=>handleSubscribeClick()}><Trans i18nKey={"header5"}> SUBSCRIBE </Trans></div>
			</div>
			<div className="headerside-bottom">
				<div className="headerside-bottom-element">
					<img className="headerside-bottom-image" src={Facebook}></img>
				</div>
				<div className="headerside-bottom-element">
					<img className="headerside-bottom-image" src={Instagram}></img>
				</div>
			</div>
		</div>
	)
}

export default HeaderSide