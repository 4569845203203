import React, { useState , useEffect, useRef } from 'react';
import './Sell.css'
import Select from 'react-select'
import ReactSlider from 'react-slider'
import axios from 'axios'

import { useTranslation, Trans } from 'react-i18next';
import { AutocompleteTest } from '../Shared/GoogleMaps/GoogleMaps';

import Close from '../Shared/Popups/Close.svg'
import ThankYouRegister from '../Shared/Popups/ThankYouRegister.png'
import SellGif from './SellGif.webp'

import SellForm from './SellForm';
//import Rating4 from './rating4.svg'



function Sell(){
	const { t } = useTranslation()
	const original = {
		'First name': {required: true, value: '', check: 'empty'},
		'Last name': {required: true, value: '', check: 'empty'},
		'Email address': {required: true, value: '', check: 'empty'},
		'Phone number': {required: true, value: '', check: 'empty'},
		'Current address': {required: true, value: '', check: 'empty'},
		'Postcode': {required: true, value: '', check: 'empty'}
	}
	const [data, setData] = useState(original)
	const [submit, setSubmit] = useState(false)
	const [key , setKey] = useState(0)
	const forceRender = () => setKey(key+1)

	const SellFormElement = (field) => {
		const [rq, setRq] = useState(false)
		const handleRequired = () => {
			if(submit==true && data[field].required==true && data[field].value.length==0) setRq(true)
			else setRq(false)
		}
		useEffect(()=>{
			handleRequired()
		  }, [data, submit])
		return(
		<div className="sell-middle-form-element">
			<div className="sell-middle-form-field" rq={String(rq)}><Trans i18nKey={field}>{field}</Trans> {data[field].required?'*':null}</div>
			<div className="sell-middle-form-box"><input className="sell-middle-form-input user-input" onChange={(e)=>setData({...data, [field]: {...data[field], value: e.target.value}})}></input></div>
		</div>
		)
	}

	const [send, setSend] = useState(false)

	const handleConfirmSubmit = () => {
		let confirmSubmit = true
		console.log(data)
		for(let entry in data){
			if(data[entry].required==true && data[entry].check=='empty' && data[entry].value.length==0) confirmSubmit=false
		}
		return confirmSubmit
	}

	const handleSubmit = () => {
		setSubmit(true)
		forceRender()
		let confirmSubmit=handleConfirmSubmit()
		if(confirmSubmit==true){
			console.log("SENT")
			axios.post('/sellemail', {data: data, language: localStorage.getItem('language').toLowerCase()})
			setSend(true)
		}
	}

	


	

	console.log("RERENDER MAIN")
	return(
		<div className="sell-wrapper">
					<div className="sell-top"><div className="sell-top-title"><Trans i18nKey={"sellheader"}>Sell your property with NL</Trans></div></div>
					<div className="sell-middle">
						<div className="sell-middle-text"><Trans i18nKey={"sellmiddletext"}>
							Whether you're looking to purchase your dream home or sell a property, our dedicated team at NL Immobilien is committed to making your 
							experience seamless and rewarding. As experts in the industry, we understand the unique aspirations and concerns that come with buying or selling a property. 
							Your goals are our priority, and we're here to provide personalized assistance tailored to your needs. Contact us today, and let's start a conversation about 
							turning your real estate dreams into reality. Whether you're a prospective buyer or a seller, your journey begins with a simple call or by filling out our 
							contact form. Trust us to be your reliable partner in the exciting adventure that lies ahead in the world of real estate.
							</Trans>
						</div>
						<div className="sell-middle-title">
							<div className="sell-middle-title-left"><Trans i18nKey={"sellmiddletitleleft"}>Still on the fence? Take the leap –</Trans></div>
							<div className="sell-middle-title-right"><Trans i18nKey={"sellmiddletitleright"}>Connect with us today!</Trans></div>
						</div>
						<div className="sell-middle-form-wrapper">
							<div className='sell-middle-form-2' send={String(send)}>
								<div className='sell-middle-thank'><Trans i18nKey={"sellmiddlethank"}>Thank you for submitting your request. We will get in touch with you shortly.</Trans></div>
							</div>
							<div className="sell-middle-form" send={String(send)}>
								<div className="sell-middle-form-left"><img src={SellGif} className="sell-middle-image"></img></div>
								<div className="sell-middle-form-right">
									{SellFormElement('First name')}
									{SellFormElement('Last name')}
									{SellFormElement('Email address')}
									{SellFormElement('Current address')}
									{SellFormElement('Postcode')}
									{SellFormElement('Phone number')}
									<div className="sell-middle-form-submit" onClick={()=>handleSubmit()}><Trans i18nKey={"sellmiddleformsubmit"}>SUBMIT</Trans></div>
								</div>
							</div>
						</div>
						
						<div className="sell-middle-bottom">	
							<div className="sell-middle-bottom-title"><Trans i18nKey={"sellmiddlebottomtitle"}>Or alternatively call us on</Trans></div>
							<div className="sell-middle-bottom-call">+49 (0176) 68005333</div>

						</div>
			        </div>
					<div className="sell-onlinequote-title"><Trans i18nKey={"sellonlinequotetitle"}>Explore Our Cutting-Edge Online Market Price Assessment Tool for Instant Estimated Quotations on Your Property</Trans></div>
						<div className="sell-online-background">	

									
										{SellForm()}
						<div className="sell-onlinequote-servicetexts">
							     <div className="sell-onlinequote-text"><Trans i18nKey={"sellonlinequotetext1"}>Our advanced algorithm swiftly calculates your property's value, providing you with accurate estimates in seconds.</Trans></div>
							     <div className="sell-onlinequote-text"><Trans i18nKey={"sellonlinequotetext2"}>Tailored to your property's specific features and location, our advanced algorithm ensures precision in determining its market worth.</Trans></div>
							     <div className="sell-onlinequote-text"><Trans i18nKey={"sellonlinequotetext3"}>Benefit from our free service, providing transparent insights into your property's value without any fees or obligations.</Trans></div>
						</div>
					</div>
					
			</div>
		)
}

export default Sell;