import React from 'react';
import axios from 'axios'
import { useTranslation, Trans } from 'react-i18next';

export const UploadProductImages = ({folder, refresh}) => {
    const handleUpload = async (event) => {
        const files = event.target.files
        let formData = new FormData()
        for (let i = 0; i < files.length; i++){
            files[i].folder = folder
            formData.append(String(folder)+'/'+files[i].name, files[i])
        }
        let result = await axios.post('/uploadproductimages', formData)
        refresh()
    }
    
    return (
        <input type="file" multiple accept="image/*" onChange={handleUpload}/>
    )
}

export const UploadEnergyImage = ({folder, refresh}) =>{
    const handleUpload = async (event) => {
        const files = event.target.files
        let formData = new FormData()
        files[0].folder = folder
        let name = String(folder)+'/energy/energy.'+files[0].name.split('.').slice(-1)[0]
        formData.append(name, files[0])
        let result = await axios.post('uploadenergyimage', formData)
        refresh()
    }
    return (
        <input type="file" accept="image/*" onChange={handleUpload}/>
    )
}

export const UploadArticleFeaturedImage = ({folder, refresh}) => {
    const handleUpload = async (event) => {
        const files = event.target.files
        let formData = new FormData()
        files[0].folder = folder
        let name = String(folder)+'/featured/featured.'+files[0].name.split('.').slice(-1)[0]
        formData.append(name, files[0])
        let result = await axios.post('uploadarticlefeaturedimage', formData)
        refresh()
    }
    return (
        <input type="file" accept="image/*" onChange={handleUpload}/>
    )
}


export const UploadBlockImage = ({folder, refresh, blockid, articleid}) => {
    const handleUpload = async (event) => {
        const files = event.target.files
        let formData = new FormData()
        files[0].folder = folder
        let name = String(folder)+'/'+files[0].name
        formData.append(name, files[0])
        formData.append("blockid", blockid)
        formData.append("articleid", articleid)
        let result = await axios.post('uploadblockimage', formData)
        refresh()
    }
    return (
        <div><div className='label'><Trans i18nKey={"upload-block"}>Upload Image</Trans></div><input type="file" accept="image/*" onChange={handleUpload}/></div>
    )
}
