import React, { useState , useEffect, useRef } from 'react';
import '../Shared/Header/Header.css'
import '../Shared/Header/HeaderSide.css'
import Language from '../Shared/Header/Language.svg'
import './LanguageSwitch.css'
import { useTranslation } from 'react-i18next';

const lngs = {
    en: { nativeName: 'English' },
    de: { nativeName: 'Deutsch' },
    gr: { nativeName: 'Greek' }
}

function LanguageSwitch({device}){
    const setLocalLanguage = (language) => localStorage.setItem('language', language)
    const getLocalLanguage = () => {  return localStorage.getItem('language')  }
    const [language, setLanguage] = useState('EN')
    const setLocalChecks = (checks) => localStorage.setItem('checks', JSON.stringify(checks))
    const getLocalChecks = () => { return JSON.parse(localStorage.getItem('checks')) }
    const [checks, setChecks] = useState([true, false, false])
    
    const [loading, setLoading] = useState(true)
    useEffect(()=>{
        let locallanguage = getLocalLanguage()
        if(locallanguage===null) setLocalLanguage('EN')
        else setLanguage(locallanguage)

        let localchecks = getLocalChecks()
        if(localchecks===null) setLocalChecks([true, false, false])
        else setChecks(localchecks)
        setLoading(false)
    }, [])
    

    const LanguageMenu = () => {
        
        const texts = ['English (EN)', 'Deutsch (DE)', 'Ελληνικά (GR)']
        const ids = ['en', 'de', 'gr']
        
        const [key, setKey] = useState(0)
        const forceRender = () => setKey(key+1)
        const { i18n } = useTranslation()
        const [menuarr, setMenuarr] = useState([])
        useEffect(()=>{
            fillMenuArr()
        }, [loading, key])
        const fillMenuArr = () => {
            if(loading===false){
                let newmenuarr = []
                for(let i = 0; i < checks.length; i++) newmenuarr.push(
                    <div className='checkbox-wrapper'>
                        <div className='checkbox-button' check={String(checks[i])} onClick={()=>handleChecks(i)}></div>
                        <div className='checkbox-text'>{texts[i]}</div>     
                    </div>
                )
                setMenuarr(newmenuarr)
            }
        }
        

        const handleChecks = (index) => {
            let newchecks = checks
            for(let i = 0; i < newchecks.length; i++) newchecks[i] = false
            newchecks[index] = true
            setChecks(newchecks)
            setLocalChecks(newchecks)
            i18n.changeLanguage(ids[index])
            setLanguage(ids[index].toUpperCase())
            setLocalLanguage(ids[index].toUpperCase())
            forceRender()
        }

          
        if(device=='Desktop') {
            return(
            <div className='desktop-language-wrapper'>
                {menuarr}
            </div>
        )
        }
        if(device==='Mobile') return(
            <div className='mobile-language-wrapper'>
                {menuarr}
            </div>
        )
    }
    if(device==='Desktop') return(
        <div className="desktop-header-right-element" >
			{LanguageMenu()}
            <img className="desktop-header-right-image" src={Language}></img>
			<div className="desktop-header-right-text">{language.toUpperCase()}</div>
	    </div>
    )
    if(device==='Mobile') return(
        <div className="headerside-middle-element">
			<div className='headerside-middle-block'>
                <div className='headerside-middle-button'>
                    <img className="headerside-middle-image" src={Language}></img>
			        <div className="headerside-middle-text">{language.toUpperCase()}</div>
                </div>
                {LanguageMenu()}

            </div>
		</div>
    )
}

export default LanguageSwitch