import React, { useState , useEffect, useRef } from 'react';
import './ArticleList.css'
import axios from 'axios';
import { NavLink } from 'react-router-dom';

import i18next from 'i18next';
import { useTranslation, Trans } from 'react-i18next';

import MenuArticle from './MenuArticle';
import Search from '../Buy/Search.svg'

function ArticleList({logged}){
    const { t } = useTranslation()

    const [articles, setArticles] = useState([])


    useEffect(()=>{
    	getArticles()
  	}, [])

    const getArticles = async() => {
        let result = await axios.post('getarticlesbycategory', {})
        setArticles(result.data.response)
        addTranslations(result.data.response)
    }

    const createOne = async () => {
        let result = await axios.get('/createarticle')
        window.location.href = '/' + String(result.data.response)
    }

    //ADD TRANSLATIONS
	const addTranslations = async (data) => {
        for (let index in data){
            let entry = data[index]
            for (let language in entry.translation){
                await i18next.addResourceBundle(language, 'translation', entry.translation[language], true, true)
                
            }
        }
	}

    //CHECKBOX FILTER THAT HAS MORE THAN 2 OPTIONS
    const CategoryMenu = () => {
        const original = [true, false, false, false, false]
        const texts = ['All', "Property Management", "Property Buying", "Property Selling", "News"]
        const filterfield = 'category'
        const [checks, setChecks] = useState(original)
        
        const [key, setKey] = useState(0)
        const forceRender = () => setKey(key+1)
        
        const handleFilterArrChange = (newchecks) => {
            let newfilter = filter
            newfilter[filterfield] = []
            for(let i = 1; i < newchecks.length; i++){
                if(newchecks[i]==true || newchecks[0]==true) newfilter[filterfield].push(texts[i])
            }
            setFilter(newfilter)
        }

        const handleChecks = (index) =>{
            let newchecks = checks
            if(texts[index]=='All') {
                newchecks = original
            }
            else if(checks[index]==false){
                newchecks[0] = false
                newchecks[index] = true
                let anyfalse = false
                for(let i = 1; i < newchecks.length; i++){ if(newchecks[i]==false) anyfalse=true}
                if(anyfalse==false) newchecks = original
            }
            else if(checks[index]==true){
                newchecks[index] = false
                let anytrue = false
                for(let i = 1; i < newchecks.length; i++){ if(newchecks[i]==true) anytrue=true}
                if(anytrue==false) newchecks = original
            }
            setChecks(newchecks)
            handleFilterArrChange(newchecks)
            forceRender()
        }

        let menuarr = []
        for(let i = 0; i < checks.length; i++) menuarr.push(
                <div className='checkbox-wrapper'>
                    <div className='checkbox-button' check={String(checks[i])} onClick={()=>handleChecks(i)}></div>
                    <div className='checkbox-text'><Trans i18nKey={texts[i]}>{texts[i]}</Trans></div>     
                </div>
        )
        return (
            <div className='articlelist-filter-wrapper'>
                {menuarr}
            </div>
        )
    }

    const articlelistHeader = () => {
        return(
            <div className='articlelist-header-wrapper'>
                  <div className="articlelist-top"><div className='articlelist-header'><Trans i18nKey={"articlelistheader"}>Browse the latest NL Immobilien articles</Trans></div></div>
                   <div className= "articlelist-elements">
                     <div className="articlelist-header-right">
					   <div className="articlelist-header-right-element " >{CategoryMenu()}<Trans i18nKey={"categories"}>CATEGORIES</Trans></div>
				    </div>
                    <div className= "articlelist-header-left">
                       <input type="text" name="s" placeholder={t('search')} className= "articlelist-header-left-element" onChange={(e)=>setFilter({...filter, query: e.target.value})}></input>
                       <img className="articlelist-header-left-search "src={Search}></img>
                </div>
             </div> 
         </div>
    
            
        
        )
    }

    const [filter, setFilter] = useState({
        category: ["Property Management", "Property Buying", "Property Selling", "News"],
        query: ""
    })

    //CHECK IF QUERY MATCHES ANY OF articleS STRING PROPERTIES
    const matchQuery = (article) => {
        for(let entry in article){
            if (typeof article[entry]==="string"){
                if(article[entry].toLowerCase().includes(filter.query.toLowerCase())) return true
            }
            if(entry==='translation'){
                for(let language in article[entry]){
                    for(let translation in article[entry][language]){
                        if(article[entry][language][translation].toLowerCase().includes(filter.query.toLowerCase())) return true
                    }
                }
            }
        }
        return false
    }

    const renderArticles = articles.map((article) => {
        if(
            filter.category.includes(article.category) &&
            matchQuery(article)    
        )return(<MenuArticle article={article}/>)
    })

    
    
    return(
        <div className='articlelist-wrapper'>
            {articlelistHeader()}
            {logged?<div className="admin-articlelist-add" onClick={()=>createOne()}><Trans i18nKey={"addarticle"}>ADD NEW ARTICLE</Trans></div>:null}
            <div className='articlelist-articles-wrapper'>{renderArticles}</div>
        </div>
    )
}

export default ArticleList