import React, { useState , useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import './Header.css';
import { useWindowSize } from "../Functions/Functions"
import LanguageSwitch from "../../Translation/LanguageSwitch"

import { useTranslation, Trans } from 'react-i18next';

import Language from './Language.svg'
import Menu from './Menu.svg'
import Logo from './Logo.png'

function Header({isMenuOpen, setMenuOpen, showRegister}){
	const { t } = useTranslation()

	const [width, height] = useWindowSize()
	
	const donutClick = () => setMenuOpen(!isMenuOpen)
	var mobile = window.matchMedia("(max-width: 1024px)")
	//MOBILE
	if (mobile.matches){
  		return(
    		<div className="mobile-header-wrapper">
				<div className="mobile-header-left">
					<div className="mobile-header-left-element" onClick={()=>donutClick()}>
						<img className="mobile-header-left-image" src={Menu}></img>
					</div>
				</div>
				<div className="mobile-header-middle">
					<NavLink to='/' className="mobile-header-logo"><img style={{width: "90px"}} src={Logo}></img></NavLink>
				</div>
				
			</div>
    	)
  	} 
  	
  	//DESKTOP
  	else{
    	setMenuOpen(false)
    	return(
    		<div className="desktop-header-wrapper">
				<div className="desktop-header-left">
					<NavLink to="/buy" className="desktop-header-left-element desktop-header-left-text"><Trans i18nKey={"header1"}>BUY</Trans></NavLink>
					<NavLink to="/sell" className="desktop-header-left-element desktop-header-left-text"><Trans i18nKey={"header2"}>SELL</Trans></NavLink>
					<NavLink to="/financial" className="desktop-header-left-element desktop-header-left-text"><Trans i18nKey={"header6"}>FINANCIAL ADVICE</Trans></NavLink>
					<NavLink to="/articles" className="desktop-header-left-element desktop-header-left-text"><Trans i18nKey={"header3"}>JOURNAL</Trans></NavLink>
				</div>
				<div className="desktop-header-middle">
					<NavLink to='/' className="desktop-header-logo"><img style={{width: "137px"}} src={Logo}></img></NavLink>
				</div>
				<div className="desktop-header-right">
					<LanguageSwitch device='Desktop'/>
					<NavLink to="/about" className="desktop-header-right-element"><Trans i18nKey={"header4"}>ABOUT</Trans></NavLink>
					<div className="desktop-header-right-element" onClick={()=>showRegister()}><Trans i18nKey={"header5"}>SUBSCRIBE</Trans></div>
				</div>
			</div>
    	)
	}


}


export default Header;