import React, { useState , useEffect, useRef } from 'react';
import { useWindowSize } from "../Functions/Functions"
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Viewing.css'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Select from 'react-select'
import axios from 'axios'

import Close from './Close.svg'
import ThankYouViewing from './ThankYouViewing.png'

import { useTranslation, Trans } from 'react-i18next';

function Viewing({viewing, hideViewing, product}){
	const original = {
		'First name': {required: true, value: '', check: 'empty'},
		'Last name': {required: false, value: '', check: 'empty'},
		'Email address': {required: true, value: '', check: 'empty'},
		'Phone number': {required: true, value: '', check: 'empty'},
		'Current address': {required: false, value: '', check: 'empty'},
		'Postcode': {required: false, value: '', check: 'empty'},
		'Where': {required: true, value: '', check: 'empty'},
		'Check1': {required: true, value: false, check: 'boolean'},
		'Check2': {required: false, value: false, check: 'boolean'},
		'Product': {required: false, value: product, check: 'none'}

	}
	const [data, setData] = useState(original)
	const [submit, setSubmit] = useState(false)
	const [key , setKey] = useState(0)
	const forceRender = () => setKey(key+1)
	const { t } = useTranslation()
	const ViewingFormElement = (field) => {
		const [rq, setRq] = useState(false)
		const handleRequired = () => {
			if(submit==true && data[field].required==true && data[field].value.length==0) setRq(true)
			else setRq(false)
		}
		useEffect(()=>{
			handleRequired()
		  }, [data, submit])
		return(
			<div className="viewing-form-element">
				<div className="viewing-form-field" rq={String(rq)}><Trans i18nKey={field}>{field}</Trans> {data[field].required?'*':null}</div>
				<div className="viewing-form-box"><input className="viewing-form-input user-input" onChange={(e)=>setData({...data, [field]: {...data[field], value: e.target.value}})}></input></div>
			</div>
			)
	}

	const ViewingFormSelect = (field, options) => {
		const [rq, setRq] = useState(false)
		const handleRequired = () => {
			if(submit==true && data['Where'].required==true && data['Where'].value.length==0) setRq(true)
			else setRq(false)
		}
		useEffect(()=>{
			handleRequired()
		  }, [data, submit])
		return(
			<div className="viewing-form-element">
				<div className="viewing-form-field" rq={String(rq)}><Trans i18nKey={field}>{field}</Trans> {data['Where'].required?'*':null}</div>
				<div className="viewing-form-box-select"><Select className="viewing-form-select" options={options} onChange={(e)=>setData({...data, 'Where': {...data['Where'], value: e.value}})}></Select></div>
			</div>
			)
	}
	const hearoptions = [
		{value: 'Instagram', label: 'Instagram'},
		{value: 'Facebook', label: 'Facebook'},
		{value: 'Google search', label: t('Google search')},
		{value: 'From another company', label: t('From another company')},
		{value: 'Previous client', label: t('Previous client')},
		{value: 'Recommendation', label: t('Recommendation')},
		{value: 'Event', label: t('Event')}
		]
	
	
	const ViewingFormCheck1 = (field='Check1') => {
		const [check, setCheck] = useState(false)
		const [rq, setRq] = useState(false)
		const handleRequired = () => {
			if(submit==true && data[field].required==true && data[field].value==false) setRq(true)
			else setRq(false)
		}
		useEffect(()=>{
			handleRequired()
		  }, [data, submit])
		const handleCheck = () => {
			setCheck(!check)
			setData({...data, 'Check1': {...data['Check1'], value: !check}})
		}
		if(check==true && viewing==false) setCheck(false)
		return(
			<div className="viewing-form-checkbox">
				<div className="viewing-form-check" check={String(data['Check1'].value)}onClick={()=>handleCheck()}></div>
				<div className="viewing-form-text" rq={String(rq)}><Trans i18nKey={"Accept"}>I accept the Privacy & Cookies Policy and Terms & Conditions</Trans> {data[field].required?'*':null}</div>
			</div>
			)
	}

	const ViewingFormCheck2 = (field='Check2') => {
		const [check, setCheck] = useState(false)
		const [rq, setRq] = useState(false)
		const handleRequired = () => {
			if(submit==true && data[field].required==true && data[field].value==false) setRq(true)
			else setRq(false)
		}
		useEffect(()=>{
			handleRequired()
		  }, [data, submit])
		const handleCheck = () => {
			setCheck(!check)
			setData({...data, 'Check2': {...data['Check2'], value: !check}})
		}
		if(check==true && viewing==false) setCheck(false)
		return(
			<div className="viewing-form-checkbox">
				<div className="viewing-form-check" check={String(data['Check2'].value)} onClick={()=>handleCheck()}></div>
				<div className="viewing-form-text" rq={String(rq)}><Trans i18nKey={"Subscribe"}>I'd like to subscribe to the newsletter to receive exclusive content and event invitations</Trans> {data[field].required?'*':null}</div>
			</div>
			)
	}

	const viewdivRef = useRef(null)

	const [send, setSend] = useState(0)

	const handleConfirmSubmit = () => {
		let confirmSubmit = true
		console.log(data)
		for(let entry in data){
			if(data[entry].required==true && data[entry].check=='empty' && data[entry].value.length==0) confirmSubmit=false
			if(data[entry].required==true && data[entry].check=='boolean' && data[entry].value==false) confirmSubmit=false
		}
		return confirmSubmit
	}

	const handleSubmit = () => {
		setSubmit(true)
		forceRender()
		let confirmSubmit=handleConfirmSubmit()
		if(confirmSubmit==true){
			console.log("SENT")
			axios.post('/viewingemail', {data: data, language: localStorage.getItem('language').toLowerCase()})
			viewdivRef.current.scrollTo({ top: 0, behavior: 'smooth' });
			setSend(true)
		}
	}
	


	const ViewingForm = () => {
		return(
			<div className="viewing-form" send={String(send)}>
				<div className="viewing-title"><Trans i18nKey={"Request"}>REQUEST A VIEWING OF</Trans></div>
				<div className="viewing-name"><Trans i18nKey={product.id+'name'}>{product.name}</Trans></div>
				<div className="viewing-location"><Trans i18nKey={product.id+'area'}>{product.area}</Trans></div>
				<div className="viewing-form-elements">
					{ViewingFormElement('First name')}
					{ViewingFormElement('Last name')}
					{ViewingFormElement('Email address')}
					{ViewingFormElement('Phone number')}
					{ViewingFormElement('Current address')}
					{ViewingFormElement('Postcode')}
					{ViewingFormSelect('Where did you first hear about us?', hearoptions)}
					{ViewingFormCheck1()}
					{ViewingFormCheck2()}
				</div>
				<div className="viewing-form-submit" onClick={()=>handleSubmit()}><Trans i18nKey={"Submit"}>SUBMIT</Trans></div>
			</div>
			)
	}

	const ViewingThank = () => {
		return(
			<div className="viewing-form-thank" send={String(send)}>
				<div className="viewing-form-thank-text1"><Trans i18nKey={"Thank you viewing"}>Thank you<br/>for submitting<br/>your request.</Trans></div>
				<div className="viewing-form-thank-text2"><Trans i18nKey={"Thank you viewing2"}>We will get back to you<br/>very shortly.</Trans></div>
				<img className="viewing-form-thank-image" src={ThankYouViewing}></img>
				<div className="viewing-form-thank-button" onClick={()=>hideViewing()}><Trans i18nKey={"Back"}>GO BACK</Trans></div>
			</div>
			)
	}
	return(
		<div className="viewing-wrapper" viewing={String(viewing)}>
			<div className="viewing-element-wrapper">
				<div className="viewing-element" ref={viewdivRef}>
					<img className="viewing-close" src={Close} onClick={()=>hideViewing()}></img>
					{ViewingForm()}
					{ViewingThank()}
				</div>
			</div>
			<div className="viewing-blur" onClick={()=>hideViewing()}></div>
		</div>
		)

}

export default Viewing;