import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Router from './Router/Router';
import './Translation/i18n'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router/>
  </React.StrictMode>
);

