import React, { useState , useEffect, useLayoutEffect } from 'react';
import './Banners.css'

import { useTranslation, Trans } from 'react-i18next';


export const Sold = () => {
	const { t } = useTranslation()
	return(
		<div className="sold-wrapper">
			<div className="sold-text"><Trans i18nKey={"SOLD"}>SOLD</Trans></div>
		</div>
		)
}

export const New = () => {
	const { t } = useTranslation()
	return(
		<div className="sold-wrapper">
			<div className="sold-text"><Trans i18nKey={"NEW"}>NEW</Trans></div>
		</div>
		)
}

