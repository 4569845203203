import React, { useState , useEffect, useRef} from 'react';
import { GoogleMap, Marker, useJsApiLoader, Circle, Autocomplete} from '@react-google-maps/api';
import './GMapPopup.css'
import Close from './Close.svg'

const apiKey = "AIzaSyA2CU7oNwBEK_5Z-p8ZMfNHuUUnknMBwBw"

const libraries = ["maps", "places"];

const LoadApi = () => {
    return(
        useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey,
        libraries
         })
    )
}

export const GMap = ({getCenter, saveCenter, logged}) =>{
    const {isLoaded} = LoadApi()
    const [center, setCenter] = useState({lat: 0, lng: -180})
    const [loading, setLoading] = useState(true)
    useEffect(()=>{
    	loadMap()
  	}, [])
    const containerStyle = {
        width: '100%',
        height: '100%'
    }

    const loadMap = async() => {
        let response = await getCenter()
        setCenter(response)
        setLoading(false)
    }

    const options = {
        streetViewControl: false,
    }



    const handleClick = async (e) => {
        if (logged) {
            let newcenter = {lat: e.latLng.lat(), lng: e.latLng.lng()}
            setCenter(newcenter)
            saveCenter(newcenter)
        }
    }
    return isLoaded ? (
        <GoogleMap
        zoom={14}
        center={center}
        options={options}
        mapContainerStyle={containerStyle}
        onClick={(e)=> handleClick(e)}
        >
        <Circle center={{lat: center.lat, lng: center.lng}} radius={400} options={{strokeColor: '#630b0b', fillColor: '#630b0b'}}/>
        </GoogleMap>
    ) : null

}


export const GMapPopup = ({getCenter, saveCenter, logged}) => {
    const handleClick = () => { if(show==false) setShow(true)}
    const [show, setShow] = useState(false)
    return(
        <div className='gmap-popup-wrapper' onClick={()=>handleClick()} show={String(show)}>
            <div className='gmap-popup-fullscreen' show={String(show)}>
                <GMap getCenter={getCenter} saveCenter={saveCenter} logged={logged}/>
                <img className='gmap-popup-close' src={Close} onClick={()=>setShow(false)}/>    
            </div>
        </div>
    )
}

export const AutocompleteTest = () => {
    const { isLoaded } = LoadApi()
    const containerStyle = {
        width: '100%',
        height: '100%'
    }
    
    const options = {
        streetViewControl: false,
    }

    const [center, setCenter] = useState({lat: 0, lng: -180})
    const [service, setService] = useState()
    const onLoadMap = async(map) => {
        setService(new window.google.maps.places.PlacesService(map))
    }
    console.log("RERENDER")
    const handleBlur = (e) => {
        const request = {
            query: e.target.value,
            fields: ["name", "geometry", "formatted_address"]
        }
        service.findPlaceFromQuery(request, (results, status) => {
            
            console.log(results[0].geometry.location.lat())
            console.log(status)
            setCenter({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng()
            })
        })
    }
    return isLoaded ?(
        <div className='autocomplete-wrapper'>
            <Autocomplete><input className='autocomplete-input user-input' onBlur={(e)=>handleBlur(e)}></input></Autocomplete>
            <div className='autocomplete-map-wrapper'>
                <GoogleMap onLoad={(map)=>onLoadMap(map)} mapContainerStyle={containerStyle} zoom={16} center={center} options={options}>
                    <Marker position={center}></Marker>
                </GoogleMap>
            </div>
        </div>
    ) : null
}
