import React, { useState , useEffect, useRef } from 'react';
import { useWindowSize } from "../Functions/Functions"
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Register.css'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Select from 'react-select'
import axios from 'axios'

import Close from './Close.svg'
import ThankYouRegister from './ThankYouRegister.png'

import { useTranslation, Trans } from 'react-i18next';

function Register({register, hideRegister}){
	const original = {
		'First name': {required: true, value: '', check: 'empty'},
		'Last name': {required: false, value: '', check: 'empty'},
		'Email address': {required: true, value: '', check: 'empty'},
		'Check1': {required: true, value: false, check: 'boolean'},
	}
	const [data, setData] = useState(original)
	const [submit, setSubmit] = useState(false)
	const [key , setKey] = useState(0)
	const forceRender = () => setKey(key+1)
	const { t } = useTranslation()
	const RegisterFormElement = (field) => {
		const [rq, setRq] = useState(false)
		const handleRequired = () => {
			if(submit==true && data[field].required==true && data[field].value.length==0) setRq(true)
			else setRq(false)
		}
		useEffect(()=>{
			handleRequired()
		  }, [data, submit])
		return(
			<div className="register-form-element">
				<div className="register-form-field"rq={String(rq)}><Trans i18nKey={field}>{field}</Trans> {data[field].required?'*':null}</div>
				<div className="register-form-box"><input className="register-form-input user-input" onChange={(e)=>setData({...data, [field]: {...data[field], value: e.target.value}})}></input></div>
			</div>
			)
	}


	const RegisterFormCheck1 = (field='Check1') => {
		const [check, setCheck] = useState(false)
		const [rq, setRq] = useState(false)
		const handleRequired = () => {
			if(submit==true && data[field].required==true && data[field].value==false) setRq(true)
			else setRq(false)
		}
		useEffect(()=>{
			handleRequired()
		  }, [data, submit])
		const handleCheck = () => {
			setCheck(!check)
			setData({...data, 'Check1': {...data['Check1'], value: !check}})
		}
		if(check==true && register==false) setCheck(false)
		return(
			<div className="register-form-checkbox">
				<div className="register-form-check"  check={String(data['Check1'].value)}onClick={()=>handleCheck()}></div>
				<div className="register-form-text" rq={String(rq)}><Trans i18nKey={"Accept"}>I accept the Privacy & Cookies Policy and Terms & Conditions</Trans> {data[field].required?'*':null}</div>
			</div>
			)
	}

	const viewdivRef = useRef(null)

	const [send, setSend] = useState(0)

	const handleConfirmSubmit = () => {
		let confirmSubmit = true
		console.log(data)
		for(let entry in data){
			if(data[entry].required==true && data[entry].check=='empty' && data[entry].value.length==0) confirmSubmit=false
			if(data[entry].required==true && data[entry].check=='boolean' && data[entry].value==false) confirmSubmit=false
		}
		return confirmSubmit
	}

	const handleSubmit = () => {
		setSubmit(true)
		forceRender()
		let confirmSubmit=handleConfirmSubmit()
		if(confirmSubmit==true){
			console.log("SENT")
			axios.post('/registeremail', {data: data, language: localStorage.getItem('language').toLowerCase()})
			viewdivRef.current.scrollTo({ top: 0, behavior: 'smooth' });
			setSend(true)
		}
	}
	
	


	const RegisterForm = () => {
		return(
			<div className="register-form" send={String(send)}>
				<div className="register-title"><Trans i18nKey={"Register title"}>SUBSCRIBE TO OUR NEWSLETTER</Trans></div>
				<div className="register-name"><Trans i18nKey={"Register description"}>Sign up to our newsletter to receive inspirational interiors, delivered straight to your inbox.</Trans></div>
				<div className="register-form-elements">
					{RegisterFormElement('First name')}
					{RegisterFormElement('Last name')}
					{RegisterFormElement('Email address')}
					{RegisterFormCheck1()}
				</div>
				<div className="register-form-submit" onClick={()=>handleSubmit()}><Trans i18nKey={"Subscribe button"}>SUBSCRIBE</Trans></div>
			</div>
			)
	}

	const RegisterThank = () => {
		return(
			<div className="register-form-thank" send={String(send)}>
				<div className="register-form-thank-text"><Trans i18nKey={"Thank you register"}>Thank you for subscribing.</Trans></div>
				<div className="viewing-form-register-back-button" onClick={()=>hideRegister()}><Trans i18nKey={"Back"}>GO BACK</Trans></div>
				
			</div>
			)
	}
	return(
		<div className="register-wrapper" register={String(register)}>
			<div className="register-element-wrapper">
				<div className="register-element" ref={viewdivRef}>
					<img className="register-close" src={Close} onClick={()=>hideRegister()}></img>
					{RegisterForm()}
					{RegisterThank()}
				</div>
			</div>
			<div className="register-blur" onClick={()=>hideRegister()}></div>
		</div>
		)

}

export default Register;