import React, { useState , useEffect, useRef } from 'react';
import './Blocks.css'

import i18next from 'i18next';
import { useTranslation, Trans } from 'react-i18next';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { render } from '@testing-library/react';

import { getLanguageWord, getParents, reOrder } from '../Shared/Functions/Functions'

import { UploadBlockImage, UploadTest } from '../Shared/UploadImages/UploadImages'

const Blocks = (article, setArticle, saveArticle, logged, getArticle) => {

    const[clicked, setClicked] = useState({})
    const handleClick = (id, value) => { setClicked({...clicked, [id]: value}) }

    const[changes, setChanges] = useState({})
    const handleChanges = (id, value) => setChanges({...changes, [id]: value})

    const idfield = 'article' + article.id + 'block'
    const [key, setKey] = useState(0)
    const forceRender = () => setKey(key+1)

    const imageRefresh = async() => {
        await getArticle()
        forceRender()
    }
    
    const handleClickOutside = (event) => {
        let newclicked = clicked
        for (let click in newclicked) { newclicked[click] = false }
        let parents = getParents(event.target)
        for (let parent in parents) {
            if(typeof parents[parent].className==="string") {
                if(parents[parent].className.includes("triggertrue")) newclicked[parents[parent].className.split('true')[1]] = true
            }       
        }
        console.log(newclicked)
        setClicked(newclicked)
        let newkey = key
        setKey(newkey+1)
    }

     //ADD TRANSLATIONS
	const { t } = useTranslation()
	const addTranslations = async (entry) => {
		for (let language in entry.translation){
			await i18next.addResourceBundle(language, 'translation', entry.translation[language], true, true)
			
		}
	}

    useEffect(()=>{
        document.addEventListener("mousedown", handleClickOutside)
        return() => { document.removeEventListener("mousedown", handleClickOutside) }
    }, [])

    useEffect(()=>{
        let newclicked = clicked
        for (let block in article.blocks){ newclicked[article.blocks[block].id] = false }
        setClicked(newclicked)
    }, [article.blocks.length])
    
    const handleDelete = (index) => {
        let newarticle = article
        let newblocks = article.blocks
        
        newblocks.splice(index, 1)
        setArticle({...article, blocks: newblocks})
        
        newarticle.blocks = newblocks
        saveArticle(article)
    }

    const handleTextChange = async(language, e, id) => {
        let newarticle = article
        let newblocks = article.blocks
        let newtranslation = article.translation
        
        if(language==='default') newblocks[id].value = e
        else newtranslation[language]['article'+article.id+'block'+id] = e
        
        newarticle.blocks = newblocks
        newarticle.translation = newtranslation
        await addTranslations(newarticle)
        setArticle({...article, blocks: newblocks, translation: newtranslation})
        saveArticle(article)        
    }

    const handleReOrder = (index, relativepos) => {
        let newarticle = article
        let newblocks = article.blocks

        newblocks = reOrder(newblocks, index, relativepos)
        setArticle({...article, blocks: newblocks})

        newarticle.blocks = newblocks
        saveArticle(article)
    }

    const renderBlocks = article.blocks.map((block, index) => {
        const id = block.id
        const text = block.value
        const transkey = 'article'+article.id+'block'+id
        let inputarr = []
        //inputarr.push(<ReactQuill theme="snow" defaultValue={block.value} onChange={(e)=>handleTextChange('default', e, id)}/>)
        for (let language in article.translation) inputarr.push(
            <div className='quill-wrapper'>
                <div className='label'>{getLanguageWord(language)}</div>
                <ReactQuill theme="snow" defaultValue={article.translation[language][transkey]} onChange={(e)=>handleTextChange(language, e, id)}/>
            </div>
        )
        return(
        <div onClick={()=>setKey(key+1)}>
            {block.type==='text'
                ?logged===false
                ?
                    <div className="block-text-wrapper">
                        <Trans i18nKey={transkey} components={{ em: <i />, strong: <strong />, u: <u />, ol: <ol />, li: <li />, h1: <h1 />, h2: <h2 />, h3: <h3 />, a: <a />, ul: <ul /> }}>{block.value}</Trans>
                    </div>
                :
                    clicked[id]===false
                    ?
                        <div className={"block-text-wrapper unselected-wrapper " + "triggertrue" + id}>
                            <Trans i18nKey={transkey} components={{ em: <i />, strong: <strong />, u: <u />, ol: <ol />, li: <li />, h1: <h1 />, h2: <h2 />, h3: <h3 />, a: <a />, ul: <ul /> }}>{block.value}</Trans>
                        </div>
                    :
                        <div className={"block-text-wrapper selected-wrapper " + "triggertrue" + id} >
                            {inputarr}
                            <div className='block-bottom-wrapper'>
                                {logged?<div className="block-delete article-admin-button" onClick={()=>handleDelete(index)}>DELETE BLOCK</div>:null}
                                {logged?<div className='block-reorder-wrapper'><div className='block-reorder-up block-reorder-button' onClick={()=>handleReOrder(index, -1)}>↑</div><div className='block-reorder-down block-reorder-button' onClick={()=>handleReOrder(index, 1)}>↓</div></div>:null}
                            </div>
                        </div>
                :null
            }
            {block.type==='image'
                ?logged===false
                ?
                    <div className="block-image-wrapper">
                        <img src={`${block.url}?${key}`}/>
                        <Trans i18nKey={transkey} components={{ em: <i />, strong: <strong />, u: <u />, ol: <ol />, li: <li />, h1: <h1 />, h2: <h2 />, h3: <h3 />, a: <a />, ul: <ul /> }}>{block.value}</Trans>
                    </div>
                :
                    clicked[id]===false
                    ?
                        <div className={"block-image-wrapper unselected-wrapper " + "triggertrue" + id}>
                            <img src={`${block.url}?${key}`}/>
                            <Trans i18nKey={transkey} components={{ em: <i />, strong: <strong />, u: <u />, ol: <ol />, li: <li />, h1: <h1 />, h2: <h2 />, h3: <h3 />, a: <a />, ul: <ul /> }}>{block.value}</Trans>
                        </div>
                    :
                        <div className={"block-image-wrapper selected-wrapper " + "triggertrue" + id} >
                            <img src={`${block.url}?${key}`}/>
                            <UploadBlockImage folder={'article'+article.id} refresh={imageRefresh} blockid={id} articleid={article.id}/>
                            {inputarr}
                            <div className='block-bottom-wrapper'>
                                {logged?<div className="block-delete article-admin-button" onClick={()=>handleDelete(index)}>DELETE BLOCK</div>:null}
                                {logged?<div className='block-reorder-wrapper'><div className='block-reorder-up block-reorder-button' onClick={()=>handleReOrder(index, -1)}>↑</div><div className='block-reorder-down block-reorder-button' onClick={()=>handleReOrder(index, 1)}>↓</div></div>:null}
                            </div>
                        </div>
                :null
            }
            
        </div>
        )
    })
    
    return (
    <div className='blocks-wrapper'>
        {renderBlocks}
        
    </div>)
}

export default Blocks