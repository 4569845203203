import React, { useState , useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import './Buy.css'
import axios from 'axios'
import Search from './Search.svg'
import { Sold , New } from "../Shared/Banners/Banners"
import Featured from "../Products/Featured"
import {MenuProduct} from "../Products/MenuProduct"
import { priceToFloat } from '../Shared/Functions/Functions';
import '../Products/MenuProduct.css'
import Select from 'react-select'

import i18next from 'i18next';
import { useTranslation, Trans } from 'react-i18next';

function Buy({logged}){
    const { t } = useTranslation()



    const [products, setProducts] = useState([])
    const [title, setTitle] = useState("ALL SALES")
    const [filter, setFilter] = useState({
        price: {priceFrom: {value: 0, label: '€ 0'}, priceTo: {value: 1000000, label:'€ 1,000,000'}}, 
        bedrooms: [1, 2, 3, 4], 
        location: ['Germany', 'Greece'],
        query: ""
    })
    const [showfeatured, setShowfeatured] = useState(true)
    useEffect(()=>{
    	getProducts()
  	}, [])
    //GET
    const getProducts = async() => {
        let result = await axios.post('/getproductsbycategory', {})
        setProducts(result.data.response)
        addTranslations(result.data.response)
    }
    
    const createOne = async () => {
        let result = await axios.get('/createProduct')
        window.location.href = '/' + String(result.data.response)
    }

    //ADD TRANSLATIONS
	const addTranslations = async (products) => {
        for (let entry in products){
            let product = products[entry]
            for (let language in product.translation){
                await i18next.addResourceBundle(language, 'translation', product.translation[language], true, true)
                
            }
        }
	}

    //SELECT FILTER
    const priceMenu = () => {
        const priceFromOptions = [
            {value: 0, label:'€ 0'},
            {value: 100000, label:'€ 100,000'},
            {value: 200000, label:'€ 200,000'},
            {value: 500000, label:'€ 500,000'},
        ]
        const priceToOptions = [
            {value: 100000, label:'€ 100,000'},
            {value: 200000, label:'€ 200,000'},
            {value: 500000, label:'€ 500,000'},
            {value: 1000000, label:'€ 1,000,000'}

        ]
        const handleFilterChange = (field, value) => {
            let price = filter.price
            price[field] = value
            if (price.priceFrom.value >= price.priceTo.value){
                priceToOptions.some((entry)=> { 
                    price.priceTo = entry
                    return entry.value>price.priceFrom.value 
                })
            }
            setFilter({...filter, price: price})
            setShowfeatured(false)
        }
        return(
            <div className='buy-filter-wrapper'>
                <div className="buy-filter-text"><Trans i18nKey={"buy01"}>FROM</Trans></div>
                <Select className="buy-filter-select"  options={priceFromOptions} value={filter.price.priceFrom} onChange={(e)=>handleFilterChange('priceFrom', e)}></Select>
                <div className="buy-filter-text"><Trans i18nKey={"buy02"}>TO</Trans></div>
                <Select className="buy-filter-select" options={priceToOptions} value={filter.price.priceTo} onChange={(e)=>handleFilterChange('priceTo', e)}></Select>
            </div>
        )
    }

  

    //CHECKBOX FILTER THAT HAS MORE THAN 2 OPTIONS
    const BedroomMenu = () => {
        const original = [true, false, false, false, false]
        const texts = ['All', 1, 2, 3, 4]
        const filterfield = 'bedrooms'
        const [checks, setChecks] = useState(original)
        
        const [key, setKey] = useState(0)
        const forceRender = () => setKey(key+1)
        
        const handleFilterArrChange = (newchecks) => {
            let newfilter = filter
            newfilter[filterfield] = []
            for(let i = 1; i < newchecks.length; i++){
                if(newchecks[i]==true || newchecks[0]==true) newfilter[filterfield].push(texts[i])
            }
            setFilter(newfilter)
            setShowfeatured(false)
        }

        const handleChecks = (index) =>{
            let newchecks = checks
            if(texts[index]=='All') {
                newchecks = original
            }
            else if(checks[index]==false){
                newchecks[0] = false
                newchecks[index] = true
                let anyfalse = false
                for(let i = 1; i < newchecks.length; i++){ if(newchecks[i]==false) anyfalse=true}
                if(anyfalse==false) newchecks = original
            }
            else if(checks[index]==true){
                newchecks[index] = false
                let anytrue = false
                for(let i = 1; i < newchecks.length; i++){ if(newchecks[i]==true) anytrue=true}
                if(anytrue==false) newchecks = original
            }
            setChecks(newchecks)
            handleFilterArrChange(newchecks)
            forceRender()
        }

        let menuarr = []
        for(let i = 0; i < checks.length; i++) menuarr.push(
                <div className='checkbox-wrapper'>
                    <div className='checkbox-button' check={String(checks[i])} onClick={()=>handleChecks(i)}></div>
                    <div className='checkbox-text'><Trans i18nKey={texts[i]}>{texts[i]}</Trans></div>     
                </div>
        )
        return (
            <div className='buy-filter-wrapper'>
                {menuarr}
            </div>
        )
    }
    //CHECKBOX FILTER THAT HAS EXACTLY 2 OPTIONS
    const LocationMenu = () => {
        const original = [true, false, false]
        const texts = ['All', 'Germany', 'Greece']
        const [checks, setChecks] = useState(original)
        const filterfield = 'location'

        const [key, setKey] = useState(0)
        const forceRender = () => setKey(key+1)
        
        const handleFilterArrChange = (newchecks) => {
            let newfilter = filter
            newfilter[filterfield] = []
            for(let i = 1; i < newchecks.length; i++){
                if(newchecks[i]==true || newchecks[0]==true) newfilter[filterfield].push(texts[i])
            }
            setFilter(newfilter)
            setShowfeatured(false)
        }

        const handleChecks = (index) =>{
            let newchecks = checks
            if(texts[index]=='All') newchecks = original
            else if(checks[index]==true) newchecks = original
            else if(checks[index]==false){
                for(let i = 0; i < newchecks.length; i++) newchecks[i]=false
                newchecks[index]=true
            }
            setChecks(newchecks)
            handleFilterArrChange(newchecks)
            forceRender()
        }

        let menuarr = []
        for(let i = 0; i < checks.length; i++) menuarr.push(
                <div className='checkbox-wrapper'>
                    <div className='checkbox-button' check={String(checks[i])} onClick={()=>handleChecks(i)}></div>
                    <div className='checkbox-text'><Trans i18nKey={texts[i]}>{texts[i]}</Trans></div>     
                </div>
        )
        return (
            <div className='buy-filter-wrapper'>
                {menuarr}
            </div>
        )
    }

    const buyHeader = () => {
        return(
            <div className='buy-header-wrapper'>
                  <div className="buy-top"><div className='buy-header'><Trans i18nKey={"buyheader"}>Browse available properties for purchase</Trans></div></div>
                   <div className= "buy-elements">
                     <div className="buy-header-right">
					   <div className="buy-header-right-element " >{priceMenu()}<Trans i18nKey={"buy1"}>PRICE</Trans></div>
					   <div className="buy-header-right-element ">{LocationMenu()}<Trans i18nKey={"buy2"}>LOCATION</Trans></div>
					   <div className="buy-header-right-element ">{BedroomMenu()}<Trans i18nKey={"buy3"}>BEDROOMS</Trans></div>
				    </div>
                    <div className= "buy-header-left">
                       <input type="text" name="s" placeholder={t('search')} className= "buy-header-left-element" onChange={(e)=>setFilter({...filter, query: e.target.value})}></input>
                       <img className="buy-header-left-search "src={Search}></img>
                </div>
             </div> 
         </div>
    
            
        
        )
    }

    //CHECK IF QUERY MATCHES ANY OF PRODUCTS STRING PROPERTIES
    const matchQuery = (product) => {
        for(let entry in product){
            if (typeof product[entry]==="string"){
                if(product[entry].toLowerCase().includes(filter.query.toLowerCase())) return true
            }
            if(entry==='translation'){
                for(let language in product[entry]){
                    for(let translation in product[entry][language]){
                        if(product[entry][language][translation].toLowerCase().includes(filter.query.toLowerCase())) return true
                    }
                }
            }
        }
        return false
    }
    //RENDER FILTERED PRODUCTS
    const buyProducts = products.map((product)=>{
        if(
            filter.price.priceFrom.value <= parseInt(product.price) && filter.price.priceTo.value >= parseInt(product.price) &&
            filter.bedrooms.includes(parseInt(product.bedrooms)) &&
            filter.location.includes(product.country) &&
            matchQuery(product)
            ) return <MenuProduct product={product}/>
    })

    const adminProducts = products.map((product)=> {
        return <MenuProduct product={product}/>
    })
    
    return(
        <div className='buy-admin-wrapper'>
            {buyHeader()}
            {logged?<div className="admin-buy-add" onClick={()=>createOne()}><Trans i18nKey={"buy4"}>Add new product</Trans></div>:null}
            
            {/* FEATURED PRODUCTS */}
		    {showfeatured?<Featured category='featured' title='FEATURED SALES' showButton={false} logged={logged}/>:null}

            {/* ALL PRODUCTS */}
            <div className='menu-products-title'><Trans i18nKey={"All sales"}>{title}</Trans></div>
            <div className='menu-products-wrapper'>
                {buyProducts}
            </div>

            {/* ADMIN VIEW */}
            {logged?<div className='menu-products-title'><Trans i18nKey={"buy5"}>ADMIN VIEW OF ALL PRODUCTS</Trans></div>:null}
            {logged
            ?<div className='menu-products-wrapper'>
                {adminProducts}
            </div>
            :null
            }
            
        </div>
    ) 
}

export default Buy