import React, { useState , useEffect, useRef} from 'react';
import { NavLink } from 'react-router-dom';
import { useWindowSize } from "../Shared/Functions/Functions"
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './SaleEntry.css'
import '../Home/Home.css'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Viewing from '../Shared/Popups/Viewing'
import axios from 'axios'
import { UploadProductImages, UploadEnergyImage } from '../Shared/UploadImages/UploadImages'
import Featured from '../Products/Featured'

import {reOrder} from '../Shared/Functions/Functions'

import Carousel1_1 from './Carousel1_1.png'
import Carousel1_2 from './Carousel1_2.png'
import Close_Carousel from './Close_Carousel.svg'
import Mail from './Mail.svg'
import Whatsapp from './Whatsapp.svg'
import Bed from './Bed.svg'

import i18next from 'i18next';
import { useTranslation, Trans } from 'react-i18next';


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Sold , New } from "../Shared/Banners/Banners"
import Select from 'react-select'

import * as FileSaver from 'file-saver';

import { GMap, GMapPopup } from '../Shared/GoogleMaps/GoogleMaps'

import Close from '../Shared/GoogleMaps/Close.svg'

import { validateUrl } from '../Shared/Functions/Functions'

function SaleEntry({showRegister, id, logged}){
	/*~~~ PRODUCT ~~~*/
	//CREATE
	const [product, setProduct] = useState({
		id: id,
        url: id,
        name: 'Enter name here',
        area: 'Enter area here',
        price: '250000',
		bedrooms: '1',
        type: 'Enter type here',
        title: 'Enter title here',
        description: 'Enter description here',
        status: 'private',
        country: 'Germany',
		images: [],
		featured: false,
		past: false,
		new: false,
		sold: false,
		homepage: false,
		mainimage: '',
		translation:{
			en:{
                [id+'name']: 'English Name',
			    [id+'area']: 'English Area',
			    [id+'title']: 'English Title',
			    [id+'description']: 'English Description',
			    [id+'type']: 'English Type',
            },
            de:{
                [id+'name']: 'Deutsch Name',
			    [id+'area']: 'Deutsch Area',
			    [id+'title']: 'Deutsch Title',
			    [id+'description']: 'Deutsch Description',
			    [id+'type']: 'Deutsch Type',
            },
            gr:{
                [id+'name']: 'Greek Name',
			    [id+'area']: 'Greek Area',
			    [id+'title']: 'Greek Title',
			    [id+'description']: 'Greek Description',
			    [id+'type']: 'Greek Type',
            }
		},
		markercenter: {lat: 49.478533974315404, lng: 8.456413027045645},
		energy: false,
		sqm: 0,
	})
	const translateFields = ['name', 'area', 'title', 'description', 'type']
	useEffect(()=>{
    	getProduct()
		console.log("USEF")
  	}, [window.location.href])
	//LOADING
	const[loading, setLoading] = useState(true)
	//GET
	const getProduct = async () => {
		let result = await axios.post('/getproduct', {id: id})
		await addTranslations(result.data.response)
		await setProduct(result.data.response)
		setLoading(false)
	}
	//ADD TRANSLATIONS
	const { t } = useTranslation()
	const addTranslations = async (product) => {
		for (let language in product.translation){
			await i18next.addResourceBundle(language, 'translation', product.translation[language], true, true)
			
		}
	}
	//SAVE
	const saveProduct = async(product) => {
		let result = await axios.post('/updateproduct', {product: product})
	}
	//DELETE
	const deleteProduct = async() => {
		let result = await axios.post('/deleteproduct', {id: id})
		setTimeout(()=>{window.location.href='/buy'}, 1000)
	}

	const formatCurrency = (amount) => { return "€" + amount.replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,') }
	//DYNAMIC FIELD
	const GenerateField = (classname, field, currency=false) => {
		const[clicked, setClicked] = useState(false)
		const handleChange = (field, e) => {
			changeField(field, e.target.value)
			if(translateFields.includes(field)===false)setClicked(!clicked)
		}

		if (logged===false) 
			if(currency) return <div className={classname}>{formatCurrency(product[field])}</div>
			else return <div className={classname}>{product[field]} {field==='bedrooms'?<img className="saleentry-bed" src={Bed}/>:null} </div>
		if (logged===true) {
			if (clicked==false) 
				if(currency) return <div className={classname} onClick={()=>setClicked(!clicked)}>{formatCurrency(product[field])}</div>
				else return <div className={classname} onClick={()=>setClicked(!clicked)}>{product[field]} {field==='bedrooms'?<img className="saleentry-bed" src={Bed}/>:null}</div>
			if (clicked==true) return <div className={classname}><input className='text-input'  autoFocus defaultValue={product[field]} onBlur={(e)=>handleChange(field, e)}></input></div>
		}
	}

	//DYNAMIC TRANSLATE FIELD
	const GenerateTranslateField = (classname, field, rich=false) => {
		const[clicked, setClicked] = useState(false)
		const idfield = product.id + field
		const[changes, setChanges] = useState(null)
		const wrapperRef = useRef(null)
		const stateRef = useRef(changes)
		const loadChanges = () => {
			let newchanges = {
				default: product[field],
				en: product.translation.en[idfield],
				de: product.translation.de[idfield],
				gr: product.translation.gr[idfield],
			}
			setChanges(newchanges)
			stateRef.current = newchanges

		}
		useEffect(()=>{
			loadChanges()
			document.addEventListener("mousedown", handleClickOutside)
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			  };
		  }, [loading])

		const handleChangeState = (language, e) => {
			let newchanges = changes
			if (rich) newchanges[language] = e
			else newchanges[language] = e.target.value
			setChanges(newchanges)
			stateRef.current = newchanges
		}
		const updateChanges = async() => {
			let newproduct = product
			for (let language in stateRef.current){
				if(language==='default') newproduct[field] = stateRef.current[language]
				else newproduct.translation[language][idfield] = stateRef.current[language]
			}
			setProduct(newproduct)
			saveProduct(newproduct)
			await addTranslations(newproduct)
			setClicked(false)
			
		}

		const handleClickOutside = (event) => {
			if(wrapperRef.current && !wrapperRef.current.contains(event.target)) updateChanges()
		}
		  
		if(loading===false){
			if(logged===false){
				if (rich) return <div className={classname}><Trans i18nKey={id+field} components={{ em: <i />, strong: <strong />, u: <u />, ol: <ol />, li: <li />, h1: <h1 />, h2: <h2 />, h3: <h3 />, a: <a />, ul: <ul /> }}>{product[field]}</Trans></div>
				else return <div className={classname}><Trans i18nKey={id+field}>{product[field]}</Trans></div>
			}
			if(logged===true){
				if (clicked===false) 
				if (rich) return <div className={classname} onClick={()=>setClicked(!clicked)}><Trans i18nKey={id+field} components={{ em: <i />, strong: <strong />, u: <u />, ol: <ol />, li: <li />, h1: <h1 />, h2: <h2 />, h3: <h3 />, a: <a />, ul: <ul /> }}>{product[field]}</Trans></div>
				else return <div className={classname} onClick={()=>setClicked(!clicked)}><Trans i18nKey={id+field}>{product[field]}</Trans></div>
				if (clicked===true) {
					let inputarr = []
					if(rich) inputarr.push(<ReactQuill theme="snow" defaultValue={product[field]} onChange={(e)=>handleChangeState('default', e)}/>)
					else inputarr.push(<div className={classname}><input className='text-input'  autoFocus defaultValue={product[field]} onChange={(e)=>handleChangeState('default', e)}></input></div>)
					for(let language in product.translation){
						if (rich) inputarr.push(<ReactQuill theme="snow" defaultValue={product.translation[language][id+field]} onChange={(e)=>handleChangeState(language, e)}/>)
						else inputarr.push(<div className={classname}><input className='text-input' defaultValue={product.translation[language][id+field]} onChange={(e)=>handleChangeState(language, e)}></input></div>)
					}
					return(
						<div className='translation-input-box' ref={wrapperRef}>
							{inputarr}
						</div>
					)
				}
			}
		}
	} 
	//DYNAMIC CHECKBOX
	const GenerateCheckbox = (classname, field) => {
		const [clicked, setClicked] = useState(false)
		const handleClick = () => {
			changeBoolean(field)
			setClicked(!clicked)
		}
		if (logged==false) return null
		if (logged==true){ 
			let wrapperclass = "checkbox-wrapper " + classname+"-wrapper"
			let buttonclass = "checkbox-button " + classname+"-button"
			let textclass = "checkbox-text " + classname+"-text"
			return(
				<div className={wrapperclass}>
					<div className={buttonclass} onClick={()=>handleClick()} check={String(product[field])}></div>
					<div className={textclass}>{field}</div>
				</div>
			)
		}
	}
	//DYNAMIC URL Field
	const GenerateURLField = (classname, field) => {
		const[clicked, setClicked] = useState(false)
		const handleChange = (field, e) => {
			let newurl = validateUrl(e.target.value)
			changeField(field, newurl)
			setClicked(!clicked)
			window.location.href='/'+String(newurl)
		}
		if (logged==false) return null
		if (logged==true) {
			if (clicked==false) return <div className={classname} onClick={()=>setClicked(!clicked)}>{product[field]}</div>
			if (clicked==true) return <div className={classname}><input className='text-input'  autoFocus defaultValue={product[field]} onBlur={(e)=>handleChange(field, e)}></input></div>
		}
	}
	//DYNAMIC SELECT Field
	const selectoptions = [
		{value: 'Germany', label: t('Germany')},
		{value: 'Greece', label: t('Greece')}
	]
	const GenerateSelectField = (classname, field) => {
		const[clicked, setClicked] = useState(false)
		const handleChange = (field, e) => {
			changeField(field, e.value)
			setClicked(!clicked)
		}

		if (logged===false) 
			return <div className={classname}><Trans i18nKey={product[field]}>{product[field]}</Trans> </div>
		if (logged===true) {
			if (clicked==false) 
				return <div className={classname} onClick={()=>setClicked(!clicked)}><Trans i18nKey={product[field]}>{product[field]}</Trans></div>
			if (clicked==true) return <div className={classname}><Select className='select-input'  autoFocus options={selectoptions} defaultValue={product[field]} onChange={(e)=>handleChange(field, e)}></Select></div>
		}
	}
	//DYNAMIC BOOLEAN SAVE
	const changeBoolean = (field) => {
		let prd = product
		prd[field] = !prd[field]
		console.log("PRD", prd)
		setProduct(prd)
		saveProduct(prd)
	}
	//DYNAMIC FIELD SAVE
	const changeField = (field, value) => {
		let prd = product
		prd[field] = value
		setProduct(prd)
		saveProduct(prd)
		
	}
	//DYNAMIC TRANSLATE FIELD SAVE
	const changeTranslateField = (field, value, language) => {
		let prd = product
		prd.translation[language][prd.id+field] = value
		setProduct(prd)
		saveProduct(prd)
		addTranslations(prd)
	}
	//DELETE IMAGE
	const deleteImage = async(filename) => {
		let newproduct = product
		newproduct.images = newproduct.images.filter(e => e !== filename);
		let result = await axios.post('/deleteproductimage', {product: newproduct, filename: filename})
		setProduct(newproduct)
		updateCarousel()
		console.log("DELETED")
	}
	//REORDER IMAGE
	const reorderImage = async(direction, image) => {
		let newproduct = product
		if(direction=='left') newproduct.images = reOrder(newproduct.images, newproduct.images.indexOf(image), -1)
		if(direction=='right') newproduct.images = reOrder(newproduct.images, newproduct.images.indexOf(image), 1)
		let result = await axios.post('/updateProduct', {product: product})
		setProduct(newproduct)
		updateCarousel()
	}
	
	/*~~~ MOBILE MEDIA QUERY IN JAVASCRIPT ~~~*/
	const [width, height] = useWindowSize()
	var mobile = window.matchMedia("(max-width: 1024px)")
	
	/*~~~ CAROUSEL  ~~~*/
	const [carousel, setCarousel] = useState(0)
	const [key, setKey] = useState(0)
	const updateCarousel = () => {
		let newkey = key+1
		setKey(newkey)

	}
	
	//GENERATE CAROUSEL IMAGES

	const carouselImages = product.images.map((image) => 
		<div className='carousel-image-wrapper'>
			{logged?<div className="carousel-image-delete" onClick={()=>deleteImage(image)}><Trans i18nKey={"sale1"}>DELETE IMAGE</Trans></div>:null}
			{logged?<div className="carousel-image-reorder-left" onClick={()=>reorderImage('left', image)}><Trans i18nKey={"sale2"}>LEFT</Trans></div>:null}
			{logged?<div className="carousel-image-reorder-right" onClick={()=>reorderImage('right', image)}><Trans i18nKey={"sale3"}>RIGHT</Trans></div>:null}
			<img src={image} className='carousel-image'></img>
		</div>	
	)
	//SHOW CAROUSEL
	const showCarousel = () => {
		var targetElement =  document.getElementsByTagName('body')[0];;
		disableBodyScroll(targetElement);
		setCarousel(true)
		window.scrollTo(0, 0)
	}
	//HIDE CAROUSEL
	const hideCarousel = () => {
		var targetElement =  document.getElementsByTagName('body')[0];;
		enableBodyScroll(targetElement);
		setCarousel(false)
	}
	
	/*~~~ REQUEST VIEWING ~~~*/
	const [viewing, setViewing] = useState(0)
	//SHOW VIEWING
	const showViewing = () => {
		setViewing(true)
	}
	//HIDE VIEWING
	const hideViewing = () => {
		setViewing(false)
	}

	//DOWNLOAD BROCHURE
	const downloadBrochure = async() => {
		let result = await axios.post('/downloadbrochure', {product: product, language: localStorage.getItem('language')}, {responseType: 'blob'})
		FileSaver.saveAs(result.data, t(product.id+'name')+" - "+t("sale6")+" - NL Immobilien.pdf")
	}

	//GOOGLE MAP
	const getCenter = async() => {
		return product.markercenter
	}

	const saveCenter = async(newcenter) => {
		let newproduct = product
		newproduct.markercenter = newcenter
		saveProduct(newproduct)
		setProduct(newproduct)
	}
	
	//ENERGY CLASS
	const EnergyClass = () => {
		const [show, setShow] = useState(false)
		const targetElement =  document.getElementsByTagName('body')[0];
		const handleOpen = () => { 
			if(show==false) {
				setShow(true)
				disableBodyScroll(targetElement);
			}
			console.log(product.energy)
		}
		const handleClose = () => {
			enableBodyScroll(targetElement)
			setShow(false)
			
		}
		return(
			<div className="energy-popup" show={String(show)} onClick={()=>handleOpen()}>
				<div className='energy-popup-fullscreen' show={String(show)}>
					<UploadEnergyImage folder={product.id} refresh={getProduct}/>
					{product.energy!==false?<img className="energy-image" src={product.energy}></img>:null}
					<img className='energy-popup-close' show={String(show)} src={Close} onClick={()=>handleClose()}/>
					HELLO
				</div>
			</div>
		)
	}


	return(
		<div className="saleentry-wrapper">
		<Viewing viewing={viewing} hideViewing={hideViewing} product={product}/>
		{/* MAIN PRODUCT PAGE */}
		<div className="saleentry-main-mobile saleentry-main" mobile={String(mobile.matches)}>
			{/* MOBILE MAIN PRODUCT PAGE */}
			
			{/* Carousel */}
			{logged?<UploadProductImages folder={product.id} refresh={getProduct}/>:null}
			{product.new?<New/>:null}
            {product.sold?<Sold/>:null}
			{product.images.length>0
			?<Carousel infiniteLoop={true} key={key} showStatus={false} showThumbs={false} preventMovementUntilSwipeScrollTolerance={true} and swipeScrollTolerance={50}>
			
			{carouselImages}
			</Carousel>
			:null
			}
			{/* Product Properties */}
			<div className="saleentry-details">
				{logged?<div className='saleentry-main-delete' onClick={()=>deleteProduct()}><Trans i18nKey={"sale4"}>DELETE PRODUCT</Trans></div>:null}
				{GenerateURLField('saleentry-main-title', 'url')}
				{GenerateTranslateField('saleentry-main-title', 'name')}
				{GenerateTranslateField('saleentry-main-location', 'area')}
				{GenerateSelectField('saleentry-main-location', 'country')}
					<div className="saleentry-main-price-share">
						<div className="saleentry-main-price-wrapper">
							{GenerateField('saleentry-main-price', 'price', true)}
							{GenerateField('saleentry-main-price', 'bedrooms')}
							<div className='saleentry-main-sqm-wrapper'>{GenerateField('saleentry-main-sqm', 'sqm')} <Trans i18nKey={"sqm"}>square meters</Trans></div>
							{GenerateTranslateField('saleentry-main-buytype', 'type')}
						</div>
						<div className="saleentry-main-share">
							<div className="saleentry-main-share-title"><Trans i18nKey={"sale5"}>SHARE</Trans></div>
							<div className="saleentry-main-share-element">
								<img className="saleentry-main-image" src={Mail}></img>
								<img className="saleentry-main-image" src={Whatsapp}></img>
							</div>
						</div>
					</div>
					
					<div className="saleentry-main-brochure" onClick={()=>downloadBrochure()}><Trans i18nKey={"sale6"}>BROCHURE</Trans></div>
					<div className="saleentry-main-brochure" ><Trans i18nKey={"map"}>MAP</Trans>{loading?null:<GMapPopup getCenter={getCenter} saveCenter={saveCenter} logged={logged} dependent={product.markercenter}></GMapPopup>}</div>
					<div className='saleentry-main-brochure'><NavLink to="/financial"><Trans i18nKey={"loan-advice"}>FINANCIAL ADVICE</Trans></NavLink></div>
					{logged?<div className="saleentry-main-brochure"><Trans i18nKey={"epc"}>ENERGY CLASS</Trans>{EnergyClass()}</div>:null}
					<div className="saleentry-main-request" onClick={()=>showViewing()}><Trans i18nKey={"sale7"}>REQUEST VIEWING</Trans></div>
					<div className="saleentry-main-register" onClick={()=>showRegister()}><Trans i18nKey={"sale8"}>REGISTER FOR SIMILAR HOMES</Trans></div>
					<div className="saleentry-main-call"><Trans i18nKey={"sale9"}>or call +49 (0176) 68005333</Trans></div>

					<div className="saleentry-checkboxes">
						{GenerateCheckbox('saleentry-main-checkbox', 'featured')}
						{GenerateCheckbox('saleentry-main-checkbox', 'past')}
						{GenerateCheckbox('saleentry-main-checkbox', 'homepage')}
						{GenerateCheckbox('saleentry-main-checkbox', 'new')}
						{GenerateCheckbox('saleentry-main-checkbox', 'sold')}
					</div>
					
			</div>
			
			{/* Product Text */}
			{GenerateTranslateField('saleentry-main-subtitle', 'title')}
			{GenerateTranslateField('saleentry-main-description', 'description', true)}
			
		</div>
		<div className="saleentry-main-desktop saleentry-main" mobile={String(mobile.matches)}>
			{/* DESKTOP MAIN PRODUCT PAGE */}
			
			{/* Carousel */}
			
			<div className="saleentry-carousel" opens={String(carousel)}>
				<img className="saleentry-carousel-close" src={Close_Carousel} onClick={()=>hideCarousel()}></img>
				{product.images.length>0
				?<Carousel className="saleentry-desktop-carousel" key={key} infiniteLoop={true} showStatus={false} showThumbs={true}>
				{carouselImages}
				</Carousel>
				:null
			}
			</div>
			
			{/* Product Text + Carousel Door */}
			<div className="saleentry-main-left">
			{logged?<UploadProductImages folder={product.id} refresh={getProduct}/>:null}
				{product.new?<New/>:null}
           		{product.sold?<Sold/>:null}
				<div className="saleentry-desktop-image" style={{backgroundImage: `url(${product.images[0]})`}} onClick={()=>showCarousel()}>
				
					<div className="saleentry-desktop-button"><Trans i18nKey={"sale10"}>VIEW PHOTOS</Trans></div>
				</div>
				{GenerateTranslateField('saleentry-main-subtitle', 'title')}
				{GenerateTranslateField('saleentry-main-description', 'description', true)}
			</div>
			
			{/* Product Properties */}
			<div className="saleentry-main-right">
				{logged?<div className='saleentry-main-delete' onClick={()=>deleteProduct()}><Trans i18nKey={"sale11"}>DELETE PRODUCT</Trans></div>:null}
				{GenerateURLField('saleentry-main-title', 'url')}
				{GenerateTranslateField('saleentry-main-title', 'name')}
				{GenerateTranslateField('saleentry-main-location', 'area')}
				{GenerateSelectField('saleentry-main-location', 'country')}
				{GenerateField('saleentry-main-price', 'price', true)}
				{GenerateField('saleentry-main-price', 'bedrooms')}
				<div className='saleentry-main-sqm-wrapper'>{GenerateField('saleentry-main-sqm', 'sqm')}<Trans i18nKey={"sqm"}>square meters</Trans></div>
				{GenerateTranslateField('saleentry-main-buytype', 'type')}
				<div className="saleentry-main-brochure" onClick={()=>downloadBrochure()}><Trans i18nKey={"sale12"}>BROCHURE</Trans></div>
				<div className="saleentry-main-brochure" ><Trans i18nKey={"map"}>MAP</Trans>{loading?null:<GMapPopup getCenter={getCenter} saveCenter={saveCenter} logged={logged}></GMapPopup>}</div>
				<div className='saleentry-main-brochure'><NavLink to="/financial"><Trans i18nKey={"loan-advice"}>FINANCIAL ADVICE</Trans></NavLink></div>
				{logged?<div className="saleentry-main-brochure"><Trans i18nKey={"epc"}>ENERGY CLASS</Trans>{EnergyClass()}</div>:null}
				<div className="saleentry-main-request" onClick={()=>showViewing()}><Trans i18nKey={"sale13"}>REQUEST VIEWING</Trans></div>
				<div className="saleentry-main-register" onClick={()=>showRegister()}><Trans i18nKey={"sale14"}>REGISTER FOR SIMILAR HOMES</Trans></div>
				<div className="saleentry-main-call"><Trans i18nKey={"sale15"}>or call +49 (0176) 68005333</Trans></div>
				<div className="saleentry-main-share">
					<div className="saleentry-main-share-title"><Trans i18nKey={"sale16"}>SHARE</Trans></div>
					<div className="saleentry-main-share-element">
						<img className="saleentry-main-image"src={Mail}></img>
						<img className="saleentry-main-image"src={Whatsapp}></img>
					</div>
				</div>
				<div className="saleentry-checkboxes">
					{GenerateCheckbox('saleentry-main-checkbox', 'featured')}
					{GenerateCheckbox('saleentry-main-checkbox', 'past')}
					{GenerateCheckbox('saleentry-main-checkbox', 'homepage')}
					{GenerateCheckbox('saleentry-main-checkbox', 'new')}
					{GenerateCheckbox('saleentry-main-checkbox', 'sold')}
				</div>
			</div>
			

		</div>
		

		{/* BELOW PRODUCT PAGE */}
		{mobile.matches
		?<div className="saleentry-interested-wrapper">
			{/* MOBILE BELOW PRODUCT PAGE */}
			<div className="saleentry-interested-title"><Trans i18nKey={"sale17"}>Interested?</Trans></div>
			<div className="saleentry-interested-subtitle"><Trans i18nKey={"sale18"}>LET'S TALK.</Trans></div>
			<div className="saleentry-interested-info">
				<div className="saleentry-interested-call">+49 (0176) 68005333</div>
				<div className="saleentry-interested-email">nl.immobilien@gmx.de</div>
			</div>
			<div className="saleentry-interested-request" onClick={()=>showViewing()}><Trans i18nKey={"sale19"}>REQUEST VIEWING</Trans></div>
		</div>
		:<div className="saleentry-interested-wrapper">
			{/* DESKTOP BELOW PRODUCT PAGE */}
			<div className="saleentry-interested-title"><Trans i18nKey={"sale20"}>Interested?</Trans></div>
			<div className="saleentry-interested-subtitle"><Trans i18nKey={"sale21"}>LET'S TALK.</Trans></div>
			<div className="saleentry-interested-info">
				<div className="saleentry-interested-call">+49 (0176) 68005333</div>
				<div className="saleentry-interested-email">nl.immobilien@gmx.de</div>	
			</div>
			<div className="saleentry-interested-request" onClick={()=>showViewing()}><Trans i18nKey={"sale22"}>REQUEST VIEWING</Trans></div>		
		</div>
		}

		{/* FEATURED PRODUCTS */}
		<Featured category='featured' title='FEATURED SALES' showButton={false} logged={logged}/>

		</div>
		)
}

export default SaleEntry;