import React, { useState , useEffect, useRef } from 'react';
import '../Privacy.css'

import { useTranslation, Trans } from 'react-i18next';

function Terms(){
    const { t } = useTranslation()

    return(
        <div className="privacy-wrapper">    
            <div className="privacy-title"><Trans i18nKey={"privacytitle"}>
                Terms and Conditions for Real Estate Services
                </Trans>
            </div>
            <div className='privacy-date'><Trans i18nKey={"privacydate"}>
                Last Updated: 04/01/2024
                </Trans>
            </div>

            <div className="privacy-chapter"><Trans i18nKey={"privacy1"}>1. Introduction</Trans></div>
            <div className="privacy-text"><Trans i18nKey={"privacy2"}>Welcome to NL Immobilien ("we," "us," or "our"). These Terms and Conditions govern your use of our real estate services and website. By accessing or using our services, you agree to comply with and be bound by these terms. If you do not agree with any part of these terms, please refrain from using our services.</Trans></div>
            <div className="privacy-chapter-end"></div>
            <div className="privacy-chapter"><Trans i18nKey={"privacy3"}>2. User Responsibilities</Trans></div>
            <div className="privacy-subchapter"><Trans i18nKey={"privacy4"}>2.1 Account Information</Trans></div>
            <div className="privacy-text"><Trans i18nKey={"privacy5"}>To access certain features of our services, you may need to create an account. You are responsible for maintaining the confidentiality of your account information, including your username and password. Any activity that occurs under your account is your responsibility.</Trans></div>
            <div className="privacy-subchapter-end"></div>
            <div className="privacy-subchapter"><Trans i18nKey={"privacy6"}>2.2 Accuracy of Information</Trans></div>
            <div className="privacy-text"><Trans i18nKey={"privacy7"}>You agree to provide accurate and up-to-date information when using our services, including property listings, contact details, and any other relevant information. We reserve the right to suspend or terminate your account if we discover that the information provided is false or misleading.</Trans></div>
            <div className="privacy-chapter-end"></div>
            <div className="privacy-chapter"><Trans i18nKey={"privacy8"}>3. Real Estate Services</Trans></div>
            <div className="privacy-subchapter"><Trans i18nKey={"privacy9"}>3.1 Property Listings</Trans></div>
            <div className="privacy-text"><Trans i18nKey={"privacy10"}>We provide a platform for property owners, buyers, sellers, and renters to connect. While we strive to ensure the accuracy of property listings, we do not guarantee the completeness, accuracy, or reliability of any information provided. Users are encouraged to verify property details independently.</Trans></div>
            <div className="privacy-subchapter-end"></div>
            <div className="privacy-subchapter"><Trans i18nKey={"privacy11"}>3.2 Agency Representation</Trans></div>
            <div className="privacy-text"><Trans i18nKey={"privacy12"}>If you choose to engage our agency for buying, selling, or renting properties, additional terms and agreements may apply. Our agency will represent your interests diligently and ethically, adhering to all applicable laws and regulations.</Trans></div>
            <div className="privacy-chapter-end"></div>
            <div className="privacy-chapter"><Trans i18nKey={"privacy13"}>4. Fees and Payments</Trans></div>
            <div className="privacy-subchapter"><Trans i18nKey={"privacy14"}>4.1 Commission Fees</Trans></div>
            <div className="privacy-text"><Trans i18nKey={"privacy15"}>Our agency may charge commission fees for certain real estate transactions. The specific fees and payment terms will be outlined in separate agreements and communicated to the parties involved. Failure to pay applicable fees may result in the suspension of services.</Trans></div>
            <div className="privacy-subchapter-end"></div>
            <div className="privacy-subchapter"><Trans i18nKey={"privacy16"}>4.2 Refund Policy</Trans></div>
            <div className="privacy-text"><Trans i18nKey={"privacy17"}>Refund policies vary based on the nature of the services provided. Refunds, if applicable, will be processed in accordance with our refund policy outlined in separate agreements or communications.</Trans></div>
            <div className="privacy-chapter-end"></div>
            <div className="privacy-chapter"><Trans i18nKey={"privacy18"}>5. Legal Disclaimers</Trans></div>
            <div className="privacy-subchapter"><Trans i18nKey={"privacy19"}>5.1 No Warranty</Trans></div>
            <div className="privacy-text"><Trans i18nKey={"privacy20"}>While we strive to provide reliable and accurate information, we do not warrant the completeness, accuracy, or reliability of our services. Users acknowledge that information presented may contain inaccuracies, and we disclaim any liability for such inaccuracies.</Trans></div>
            <div className="privacy-subchapter-end"></div>
            <div className="privacy-subchapter"><Trans i18nKey={"privacy21"}>5.2 Third-Party Links</Trans></div>
            <div className="privacy-text"><Trans i18nKey={"privacy22"}>Our website may contain links to third-party websites or services. We are not responsible for the content, accuracy, or practices of these third-party sites. Accessing these links is at your own risk.</Trans></div>
            <div className="privacy-chapter-end"></div>
            <div className="privacy-chapter"><Trans i18nKey={"privacy23"}>6. Privacy Policy</Trans></div>
            <div className="privacy-text"><Trans i18nKey={"privacy24"}>We are committed to protecting your privacy. Our Privacy Policy, available on our website, outlines how we collect, use, and disclose personal information. By using our services, you consent to the practices described in our Privacy Policy.</Trans></div>
            <div className="privacy-chapter-end"></div>
            <div className="privacy-chapter"><Trans i18nKey={"privacy25"}>7. Termination of Services</Trans></div>
            <div className="privacy-text"><Trans i18nKey={"privacy26"}>We reserve the right to terminate or suspend your access to our services at any time, with or without cause. Termination may result from a breach of these terms, violation of applicable laws, or any other reason deemed necessary by our agency.</Trans></div>
            <div className="privacy-chapter-end"></div>
            <div className="privacy-chapter"><Trans i18nKey={"privacy27"}>8. Changes to Terms and Conditions</Trans></div>
            <div className="privacy-text"><Trans i18nKey={"privacy28"}>We may update these terms and conditions periodically to reflect changes in our services, legal requirements, or other considerations. Users are encouraged to review these terms regularly. The "Last Updated" date indicates when the most recent changes were made.</Trans></div>
            <div className="privacy-chapter-end"></div>
            <div className="privacy-chapter"><Trans i18nKey={"privacy29"}>9. Governing Law</Trans></div>
            <div className="privacy-text"><Trans i18nKey={"privacy30"}>These terms and conditions are governed by the laws of Germany. Any disputes arising out of or related to these terms shall be resolved through arbitration in accordance with the rules of the Germanic Law, and judgment upon the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof.</Trans></div>
            <div className="privacy-chapter-end"></div>
            <div className="privacy-chapter"><Trans i18nKey={"privacy31"}>10. Contact Us</Trans></div>
            <div className="privacy-text"><Trans i18nKey={"privacy32"}>If you have any questions or concerns about these terms and conditions, please contact us at info@nli.com.</Trans></div>
        </div>
    )
}

export default Terms