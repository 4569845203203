import React, { useState , useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Home.css';
import { useWindowSize } from "../Shared/Functions/Functions"
import { Sold , New } from "../Shared/Banners/Banners"
import Featured from "../Products/Featured"
import axios from 'axios'
import Select from 'react-select'

import { useTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import Front1 from './Front1.png'
import Sponsor1 from './Sponsor1.svg'
import Sponsor2 from './Sponsor2.svg'

function Home({logged}){
	const [width, height] = useWindowSize()
	var mobile = window.matchMedia("(max-width: 1024px)")

	const { t } = useTranslation()
	
	const category = 'homepage'
	const [products, setProducts] = useState({})
	const [featured, setFeatured] = useState({category: category, products: []})
	const [key, setKey] = useState(0)
	useEffect(()=>{
    	getProducts()
  	}, [])
    //GET
    const getProducts = async() => {
        let result = await axios.post('/getfeaturedbycategory', {category: category})
        setProducts(result.data.response.products)
        setFeatured(result.data.response.featured)
		addTranslations(result.data.response.products)
    }
    //SAVE
    const saveFeatured = async() => {
        let result = await axios.post('/addfeaturedbycategory', {category: category, featured: featured})
    }
	 //UPDATE
	 const updateRender = () => setKey(key+1)
	//ADD TRANSLATIONS
	const addTranslations = async (products) => {
        for (let entry in products){
            let product = products[entry]
            for (let language in product.translation){
                await i18next.addResourceBundle(language, 'translation', product.translation[language], true, true)
                
            }
        }
	}


	/*~~~ RENDER SELECT FROM PRODUCTS TO INSERT TO FEATURED ~~~*/
	const renderSelect = () => {
		//GET OPTIONS BASED ON PRODUCTS
		let options = []
		const getOptions = () => {
			for (let product in products){
				options.push({
					value: products[product].id,
					label: products[product].name,
				})
			}
		}
		getOptions()
		const handleChange = async(e) => {
			let newfeatured = featured
			newfeatured['products'] = [e.value]
			await saveFeatured(newfeatured)
			setFeatured(newfeatured)
			console.log(newfeatured) 
			updateRender()
		}
		return <Select className="featured-select" options={options} onChange={(e)=>handleChange(e)}/>
	}

	const findProduct = (id) => {
		for (let entry in products){
			if (id == products[entry].id) {return products[entry]}
		}
	}

	const renderHomeFront = () => {
		let product = findProduct(featured.products[0])
		return(
			<div className="home-front-inside-wrapper">
				<div className="home-front-display">
					<img className="home-front-image" src={product.images[0]}></img>
				</div>
				<div className="home-front-text">
					<div className="home-front-info"><Trans i18nKey={"home1"}>TODAY AT NL IMMOBILIEN</Trans></div>
					<div className="home-front-title"><Trans i18nKey={product.id+'title'}>{product.title}</Trans></div>
					<NavLink to={"/"+product.url} className="home-front-button"><Trans i18nKey={"home2"}>VIEW SALES LISTING</Trans></NavLink>
				</div>
			</div>
		)
	}

	return(
		<div className="home-wrapper">
			{logged?
            <div className='featured-select-wrapper'>
                <div className='label'><Trans i18nKey={"ADD"}>ADD PRODUCT</Trans></div>
                {renderSelect()}
            </div>
            :null}
			<div className="home-front-wrapper">
				{featured.products.length > 0
				?renderHomeFront()
				:null
				}
			</div>

			<div className="home-sell-wrapper">
				<div className="home-sell-text"><Trans i18nKey={"home3"}>'A truly professional and reliable team, making every step in the real estate process a positive and rewarding one.'</Trans> <i></i></div>
				<NavLink to="/sell" className="home-sell-button"><Trans i18nKey={"home4"}>SELL WITH US</Trans></NavLink>
			</div>

			<Featured category='featured' title='FEATURED SALES' showButton={true} logged={logged}/>

			<div className="home-appraisal-wrapper">
				<div className="home-appraisal-title"><Trans i18nKey={"home5"}>“Market leading experts in getting the best value for your property” — </Trans></div>
				<NavLink to="/sell" className="home-appraisal-button"><Trans i18nKey={"home6"}>HOW MUCH IS MY HOME WORTH?</Trans></NavLink>
			</div>

			<div className="home-info-wrapper">
				<div className="home-info-main">
					<div className="home-info-text"><Trans i18nKey={"home7"}>Elevate your living experience with our unparalleled expertise in the art of property acquisition.</Trans></div>
					<NavLink to="/about" className="home-info-button"><Trans i18nKey={"home8"}>ABOUT US</Trans></NavLink>
				</div>
			</div>

			<Featured category='past' title='PAST SALES' showButton={true} logged={logged}/>

			<div className="home-sponsor-wrapper">
				<div className="home-sponsor-title"><Trans i18nKey={"home9"}>Meet our partners</Trans></div>
				<div className="home-sponsor-element">
					<img className="home-sponsor-image1" src={Sponsor1}></img>
					<img className="home-sponsor-image2" src={Sponsor2}></img>
				</div>
			</div>

		</div>
		)
}


export default Home;