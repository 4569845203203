import React, { useState , useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './Router.css';
import ScrollToTop from './ScrollToTop';
import axios from 'axios'

import Home from '../Home/Home'
import SaleEntry from '../SaleEntry/SaleEntry'
import About from '../About/About'
import Sell from '../Sell/Sell'
import Financial from '../Financial/Financial'
import Buy from '../Buy/Buy'

import ArticleList from '../Articles/ArticleList'
import Article from '../Articles/Article'

import Header from '../Shared/Header/Header';
import HeaderSide from '../Shared/Header/HeaderSide';
import Footer from '../Shared/Footer/Footer';

import Cookies from '../Privacy/Cookies/Cookies';
import Terms from '../Privacy/Terms/Terms'

import CookiesForm from '../Shared/CookiesForm/CookiesForm'

import Login from '../Admin/Login'

import Register from '../Shared/Popups/Register'


function App(){
	const [isMenuOpen, setMenuOpen] = useState(false)
	const [login, setLogin] = useState(false)
	const [urls, setUrls] = useState([])
	const [articleurls, setArticleUrls] = useState([])
	useEffect(()=>{
    	window.scrollTo(0, 0)
    	setMenuOpen(false)
		checklogin()
		checkUrls()
		checkArticleUrls()
  	}, [])

	
	

	const handleMenuOpen = () => {
		if(isMenuOpen) setMenuOpen(false)
	}

	const [register, setRegister] = useState(0)
	const showRegister = () => setRegister(true)
	const hideRegister = () => setRegister(false)

	
	const checkUrls = async () => {
		const response = await axios.get('/findurls')
		if(response.data.status==200) setUrls(response.data.response)
	}

	const checkArticleUrls = async () => {
		const response = await axios.get('/findarticleurls')
		if(response.data.status==200) setArticleUrls(response.data.response)
	}
	
	const checklogin = async () => {
		const response = await axios.get('/checklogin')
		if (response.data.response==true) setLogin(true)
	}
	
	const generateProducts = () => {
		let routearr = []
		if(urls.length>0){
			for (let url in urls){
				routearr.push(
					<Route path={"/"+String(urls[url]['url'])} element={<SaleEntry showRegister={showRegister} id={urls[url]['id']} logged={login}/>}/>
				)
			}
		}
		return routearr
	}

	const generateArticles = () => {
		let routearr = []
		if(articleurls.length>0){
			for (let url in articleurls){
				routearr.push(
					<Route path={"/"+String(articleurls[url]['url'])} element={<Article showRegister={showRegister} id={articleurls[url]['id']} logged={login}/>}/>
				)
			}
		}
		return routearr
	}


	return(
		<div className="bigwrapper" ismenuopen={isMenuOpen.toString()}>
			<BrowserRouter>
			
			<HeaderSide isMenuOpen={isMenuOpen} handleMenuOpen={handleMenuOpen} showRegister={showRegister}/>
			<Register register={register} hideRegister={hideRegister}/>
			<div className="wrapper" onClick={()=>handleMenuOpen()}>
				
					<ScrollToTop/>
					<Header isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} showRegister={showRegister}/>
					
					<div className="main-wrapper">
    	    			<Routes>
    	    	  			<Route path="/" element={<Home logged={login}/>} />
    	    	  			<Route path="/about" element={<About/>}/>
    	    	  			<Route path="/sell" element={<Sell/>}/>
							<Route path="/financial" element={<Financial/>}/>
							<Route path="/buy" element={<Buy logged={login}/>}/>
							<Route path="/privacy-and-cookies" element={<Cookies/>}/>
							<Route path="/terms-and-conditions" element={<Terms/>}/>
							<Route path="/admin-url" element={<Login logged={login}/>}/>
							{generateProducts()}
							<Route path="/articles" element={<ArticleList logged={login}/>}/>
							{generateArticles()}
    	    			</Routes> 			
    	  			</div>
    	  			<Footer showRegister={showRegister}/>
					<CookiesForm/>
			
			</div>
			</BrowserRouter>
		</div>
	)
}

export default App;