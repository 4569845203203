import React, { useState , useEffect } from 'react';
import './About.css'

import { useTranslation, Trans } from 'react-i18next';

import Person1 from './Person1.png'
import Person2 from './Person2.png'
import Person3 from './Person3.png'
import PersonD from './PersonD.png'
import Person4 from './Person4.png'
import Person5 from './Person5.png'
import Person6 from './Person6.png'

function About(){
	const { t } = useTranslation()
	return(
		<div className="about-wrapper">
			
			{/* ~~~ Top Banner Section ~~~  */}
			<div className="about-banner">
				<div className="about-row1">
					<div className="about-title"><Trans i18nKey={"abouttitle"}>About us</Trans></div>
					<div className="about-text"><Trans i18nKey={"abouttext"}>Welcome to NL Immobilien, our real estate company based in Rheinland-Pfalz, Germany. Specializing in property sales, we operate not only in Germany but also extend our expertise to other countries, with a strong focus on the vibrant real estate market in Greece. Our dedicated team assists both buyers and property owners, ensuring seamless transactions and effective property promotion. Explore a world of opportunities with our trusted real estate services.</Trans></div>
				</div>
				<div className="about-row2"></div>
			</div>
			
			
			{/* ~~~ People Section ~~~  */}
			<div className="about-people">
				{/* ~~~ Person 1 ~~~  */}
				<div className="about-people-row-left">
					<div className="about-person-wrapper">
						<img className="about-person-image" src={Person1}></img>
						<div className="about-person-text">
							<div className="about-person-name"><Trans i18nKey={"aboutname1"}>Nektarios Thamnopoulos</Trans></div>
							<div className="about-person-role"><Trans i18nKey={"aboutrole1"}>Founder and Director  · NL Immobilien </Trans></div>
							<div className="about-person-description"><Trans i18nKey={"aboutdescription1"}>As the Founder and Director of NL Immobilien, I am proud to lead a team dedicated to providing exceptional real estate services. With a passion for property and a commitment to client satisfaction, we strive to make your real estate journey seamless and successful.</Trans></div>
						</div>
					</div>
					
				</div>
				{/* ~~~ Person 2 ~~~  */}
				<div className="about-people-row-right">
					<div className="about-person-wrapper">
						
						<div className="about-person-text-right">
							<div className="about-person-name"><Trans i18nKey={"aboutname2"}>Elisabeth Hefner</Trans></div>
							<div className="about-person-role"><Trans i18nKey={"aboutrole2"}>Executive Assistant </Trans></div>
							<div className="about-person-description"><Trans i18nKey={"aboutdescription2"}>Having the role of the executive assistant, I contribute to a dynamic team, ensuring smooth operations and supporting our shared goal of delivering excellence in the real estate industry.</Trans></div>
						</div>
						<img className="about-person-image" src={Person2}></img>
					</div>
					
				</div>
			

			{/* ~~~ Person 3 ~~~  */}
			<div className="about-people-row-left">
					<div className="about-person-wrapper">
					<img className="about-person-image" src={Person3}></img>
						<div className="about-person-text">
							<div className="about-person-name"><Trans i18nKey={"aboutname3"}>George Meyer</Trans></div>
							<div className="about-person-role"><Trans i18nKey={"aboutrole3"}>Real Estate Agent</Trans></div>
							<div className="about-person-description"><Trans i18nKey={"aboutdescription3"}>With extensive experience in the real estate industry, I am honored to be a part of a community which thrives on helping clients navigate the intricacies of real estate.</Trans></div>
						</div>
						
					</div>
					
				</div>
				
		

			{/* ~~~ Person 4 ~~~  */}
			<div className="about-people-row-right">
					<div className="about-person-wrapper">
						
						<div className="about-person-text-right">
							<div className="about-person-name"><Trans i18nKey={"aboutname4"}>Anna Moraiti</Trans></div>
							<div className="about-person-role"><Trans i18nKey={"aboutrole4"}>Real Estate Agent</Trans></div>
							<div className="about-person-description"><Trans i18nKey={"aboutdescription4"}>As a valued team member in our real estate family, my commitment is to assist clients in a way that ensures their journey unfolds exactly as they desire. Our collective efforts consistently lead to successful outcomes, bringing our clients' real estate vision to reality.</Trans></div>
						</div>
						<img className="about-person-image" src={PersonD}></img>
					 </div>
					
			    	</div>
		</div>
			
			<div className="reviews-wrapper">
				<div className="reviews-titles">
					<div className="reviews-top"><div className="reviews-top-title"><Trans i18nKey={"aboutreviews1"}>Customer Testimonials:</Trans></div></div>
					<div className="reviews-top"><div className="reviews-top-title-right"><Trans i18nKey={"aboutreviews2"}>Hear What Our Clients Have To Say.</Trans></div></div>
					</div>
			
					<div className="reviews-person-wrapper">
					<img className="reviews-person-image" src={Person4}></img>
					<div className="reviews-person-text">
						<div className="reviews-person-description"><Trans i18nKey={"aboutreviewsdescr1"}>"Had a great experience with NL Immobilien! They made my home-buying journey easy and personal. The team was responsive and found me the perfect place. Highly recommend!"</Trans></div>
						<div className="reviews-person-name">Carla, Koblenz</div>
					</div>
				</div>

					<div className="reviews-person-wrapper">
					<img className="reviews-person-image" src={Person5}></img>
					<div className="reviews-person-text">
						<div className="reviews-person-description"><Trans i18nKey={"aboutreviewsdescr2"}>"After exploring numerous options, we found a standout in this real estate company. Their evaluation for our property aligned seamlessly with our own estimation."</Trans></div>
						<div className="reviews-person-name">Lea & Paul , Mannheim</div>
					</div>
				</div>

		            <div className="reviews-person-wrapper">
					<img className="reviews-person-image" src={Person6}></img>
					<div className="reviews-person-text">
						<div className="reviews-person-description"><Trans i18nKey={"aboutreviewsdescr3"}>"Impressive handling of legal and documentation aspects. The NL team ensured us a smooth transaction, demonstrating expertise in navigating paperwork. Commendable professionalism in real estate dealings."</Trans></div>
						<div className="reviews-person-name">Kevin , Frankfurt</div>
					</div>

				 </div>

		       </div>

			</div>

		)
}



export default About;