import React, { useState , useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import './Footer.css';

import { useTranslation, Trans } from 'react-i18next';

import Facebook from '../Header/Facebook.svg'
import Instagram from '../Header/Instagram.svg'
import Subscribe from './Subscribe.svg'

function Footer({showRegister}){
	const { t } = useTranslation()

	return(
		<div className="footer-wrapper">
			<div className="footer-about">
				<NavLink to="/about" className="footer-about-title footer-title"><Trans i18nKey={"footer1"}>ABOUT</Trans></NavLink>
				<div className="footer-about-element footer-text"><Trans i18nKey={"footer2"}>Company</Trans></div>
				<div className="footer-about-element footer-text"><Trans i18nKey={"footer3"}>People</Trans></div>
				<div className="footer-about-element footer-text"><Trans i18nKey={"footer4"}>Past Sales</Trans></div>
				<div className="footer-about-element footer-text"><Trans i18nKey={"footer5"}>Reviews</Trans></div>
			</div>
			<div className="footer-contact">
				<div className="footer-contact-title footer-title"><Trans i18nKey={"footer7"}>CONTACT</Trans></div>
				<div className="footer-contect-element footer-text"><Trans i18nKey={"footer8"}>Request an appraisal</Trans></div>
				<div className="footer-contect-element footer-text"><Trans i18nKey={"footer9"}>Receive property alerts</Trans></div>
				<div className="footer-contect-element footer-text">+49 (0176) 68005333</div>
				<div className="footer-contect-element footer-text">nl.immobilien@gmx.de</div>
			</div>
			<div className="footer-misc">
				<div className="footer-subscribe">
					<div className="footer-subscribe-title footer-title2"><Trans i18nKey={"footer10"}>SUBSCRIBE TO OUR NEWSLETTER</Trans></div>
					<img className="footer-subscribe-image" onClick={()=>showRegister()} src={Subscribe}></img>
				</div>
				<div className="footer-social">
					<div className="footer-social-title footer-title2"><Trans i18nKey={"footer11"}>FOLLOW US</Trans></div>
					<div className="footer-social-element">
						<img className="footer-social-image" src={Facebook}></img>
						<img className="footer-social-image" src={Instagram}></img>
					</div>
				</div>
				<div className="footer-copyright">
					<div className="footer-copyright-element">© 2024</div>
					<NavLink to="/privacy-and-cookies" className="footer-copyright-element"><Trans i18nKey={"footer12"}>Privacy & Cookies Policy</Trans></NavLink>
					<NavLink to="/terms-and-conditions" className="footer-copyright-element"><Trans i18nKey={"footer13"}>Terms & Conditions</Trans></NavLink>
				</div>
			</div>
		</div>
		)
}


export default Footer;