import React, { useState , useEffect, useRef } from 'react';
import './MenuArticle.css'

import { NavLink } from 'react-router-dom';

import i18next from 'i18next';
import { useTranslation, Trans } from 'react-i18next';

function MenuArticle({article}){
    return(
        <NavLink to={'/'+article.url} className="menuarticle-wrapper">
            <div className='menuarticle-image-wrapper'><img src={article.featured} className='menuarticle-image'></img></div>
            <div className='menuarticle-info'>
                <div className='menuarticle-title'><Trans i18nKey={"article"+article.id+"title"}>{article.title}</Trans></div>
                <div className='menuarticle-date'><Trans i18nKey={'article'+article.id+'date'}></Trans></div>
                <div className='menuarticle-category'><Trans i18nKey={article.category}>{article.category}</Trans></div>
            </div>
        </NavLink>
    )
}

export default MenuArticle;