import React, { useState , useEffect, useRef } from 'react';
import './Financial.css'

import { useTranslation, Trans } from 'react-i18next';

function Financial(){

	const { t } = useTranslation()

	return(
		
		<div className="financial-wrapper">
			<div className="financial-background"></div>
			<div className="financial-top"><div className="financial-top-title"><Trans i18nKey={"financialheader"}>Understanding Your Financial Options: Navigating Home Loans with NL Immobilien</Trans></div></div>
			
			<div className="financial-texts">
				
				<div className="financial-box">
					    <div className="financial-middle">
							<div className="financial-middle-text-left"><Trans i18nKey={"financialmiddletexta"}>Are you considering purchasing a home but feeling overwhelmed by the intricacies of securing a bank loan?</Trans></div>
							<div className="financial-middle-text-right"><Trans i18nKey={"financialmiddletextb"}><br></br>At NL Immobilien, we understand that navigating the world of real estate financing can be a daunting task. That's why we are committed to providing you with the guidance and information you need to make informed decisions.</Trans></div>
						</div>
					</div>
				<div className="financial-box2">
						<div className="financial-middle-title">
							<div className="financial-middle-title-1"><Trans i18nKey={"financialmiddletitle1"}>EXPERT FINANCIAL ADVICE TAILORED TO YOUR NEEDS</Trans></div>
							<div className="financial-middle-text2"><Trans i18nKey={"financialmiddletext2"}>Our team of well-informed professionals is dedicated to assisting you in understanding your financial options. Whether you are a first-time homebuyer or looking to upgrade to your next property, we offer personalized financial advice to guide you through the process. Our expertise extends to various aspects of real estate financing, ensuring that you are well-equipped to make sound financial decisions.</Trans></div>
						</div>
						<div className="financial-middle-title">
							<div className="financial-middle-title-1"><Trans i18nKey={"financialmiddletitle2"}>STRATEGIC PARTNERSHIP WITH DEUTSCHE BANK</Trans></div>
							<div className="financial-middle-text2"><Trans i18nKey={"financialmiddletext3"}>We are proud to announce our partnership with Deutsche Bank, a trusted name in the financial industry. This collaboration allows us to offer our clients a streamlined process in securing bank loans for their home purchases. Deutsche Bank's commitment to excellence aligns seamlessly with our values, ensuring that you receive top-notch service throughout your home-buying journey.</Trans></div>
						</div>
				</div>

				<div className="financial-middle-box-wrapper">
					<div className="financial-second-title"><Trans i18nKey={"financialsecondheader"}>HOW CAN WE ASSIST YOU</Trans></div>
					<div className='financial-middle-box'>
						<div className="financial-middle-container2">
							<div className="financial-middle-title-3"><Trans i18nKey={"financialmiddletitle3"}>1. PERSONALIZED CONSULTATIONS:</Trans></div>
							<div className="financial-middle-text2"><Trans i18nKey={"financialmiddletext4"}>Our experienced professionals are available for personalized consultations to discuss your financial goals and provide tailored advice based on your unique circumstances.</Trans></div>
						</div>
						
						<div className="financial-middle-container2">
							<div className="financial-middle-title-3"><Trans i18nKey={"financialmiddletitle4"}>2. COMPREHENSIVE INFORMATION:</Trans></div>
							<div className="financial-middle-text2"><Trans i18nKey={"financialmiddletext5"}>We offer a wealth of information on various financing options, interest rates, and terms, empowering you to make well-informed decisions about your home loan.</Trans></div>
						</div>
						
						<div className="financial-middle-container2">
							<div className="financial-middle-title-3"><Trans i18nKey={"financialmiddletitle5"}>3. SEAMLESS BOOKING PROCESS:</Trans></div>
							<div className="financial-middle-text2"><Trans i18nKey={"financialmiddletext6"}>Through our cooperation with Deutsche Bank, we facilitate a smooth and efficient booking process for your loan. Our team will guide you every step of the way, ensuring a hassle-free experience.</Trans></div>
						</div>
			</div>
		</div>
		        <div className="financial-box3"> 
					<div className="financial-middle-bottom">	
						<div className="financial-middle-bottom-title"><Trans i18nKey={"financialmiddlebottomtitle"}>For further details or inquiries regarding our financing support system, please do not hesitate to call us on</Trans></div>
						<div className="financial-middle-bottom-call">+49 (0176) 68005333</div>
					</div>
			   </div>
		    </div>
		  </div>
		
				
				
	)  
}

export default Financial